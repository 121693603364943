import {
  FUEL_TYPES,
  IInvoice,
  IPayment,
  LineItem,
  LineItemType,
  discountType,
  paymentTypes,
} from "src/interfaces";
import get from "lodash/get";
import { toCents } from "src/utils/currency.utils";
import { calculateOriginalAmount } from "src/features/utils";
import partition from "lodash/partition";
import { calculateFees } from "src/services/payments.api";

export const CARD_TYPES = [paymentTypes.CARD_STRIPE, paymentTypes.CARD_FWD];

export const generateLineItems = (
  invoice: IInvoice,
  selectedPayments: Record<string, IPayment[]>,
  amounts: Record<string, number>,
  paymentMethods: Partial<Record<string, IPayment[]>> | null,
  fnGetPaymentFees: (paymentType: paymentTypes) => boolean,
  fnGetTax: (amount: number) => number
) => {
  console.log("generateLineItems", invoice.lineItems);
  if (!invoice?.lineItems) return [];
  if (!paymentMethods || !Object.keys(paymentMethods).length)
    return invoice?.lineItems;
  const lineItems: LineItem[] = [];
  const [fuelLineItems, restOfItems] = partition(
    invoice?.lineItems ?? [],
    (li: LineItem) => FUEL_TYPES.includes(li.type)
  );

  if (fuelLineItems.length === 2) {
    const fuelChargeItem = fuelLineItems.find(
      (li) => li.type === LineItemType.FUEL_CHARGE
    );
    const fuelReimbursementItem = fuelLineItems.find(
      (li) => li.type === LineItemType.FUEL_REIMBURSEMENT
    );
    if (fuelChargeItem && fuelReimbursementItem) {
      const originalTotal =
        fuelChargeItem?.originalTotal ?? fuelChargeItem.total;
      const displayQty = fuelChargeItem?.displayQty ?? fuelChargeItem.quantity;
      const total = fuelChargeItem.total + fuelReimbursementItem.total;

      restOfItems.push({
        ...fuelChargeItem,
        originalTotal,
        displayQty,
        quantity: total / fuelChargeItem.rate,
        total,
        derivedLineItem: fuelReimbursementItem,
      });
      restOfItems.push({
        ...fuelReimbursementItem,
        parentDerivedLineItem: fuelChargeItem,
        isDerived: true,
      });
    }
  }

  const allLineItemsTogether =
    fuelLineItems.length !== 2 ? invoice?.lineItems : restOfItems;
  allLineItemsTogether.forEach((element) => {
    const newLineItem = {
      ...element,
      originalQty: element?.originalQty ?? element.quantity,
      originalTotal: element?.originalTotal ?? element.total,
      originalRate: element?.originalRate ?? element.rate,
    };

    if (element.type !== LineItemType.CREDIT && !element?.isDerived) {
      (selectedPayments[getLineItemId(newLineItem)] ?? []).forEach(
        (selectedPayment) => {
          const rate = get(
            selectedPayment,
            `additionalData.aircraftRates[${element?.aircraftId}]`,
            element.rate
          );
          const amountId = `${getLineItemId(newLineItem)}-${getPaymentId(
            selectedPayment
          )}`;
          // Total: it has subtotal plus the fees. We need to reverse calculate fees and qty
          const total = amounts[amountId] ?? 0;
          const isTaxable = !!newLineItem?.tax;
          const totalNoFeesNoTax = calculateOriginalAmount(
            total,
            fnGetPaymentFees(selectedPayment.type),
            false,
            selectedPayment.type,
            isTaxable ? fnGetTax : null
          );
          const taxes = isTaxable ? fnGetTax(totalNoFeesNoTax) : 0;

          if (CARD_TYPES.includes(selectedPayment.type)) {
            const quantity = totalNoFeesNoTax / newLineItem.rate;

            lineItems.push({
              ...newLineItem,
              rate: newLineItem.rate,
              isDerived: true,
              total: total,
              quantity: isNaN(quantity) ? 0 : quantity,
              tax: taxes,
              derivedPaymentId: getPaymentId(selectedPayment),
              derivedType: selectedPayment.type,
            });
            return;
          }
          if (typeof rate === "number") {
            const quantity = totalNoFeesNoTax / rate;
            lineItems.push({
              ...newLineItem,
              rate,
              total: total,
              quantity: isNaN(quantity) ? 0 : quantity,
              tax: taxes,
              isDerived: true,
              derivedPaymentId: getPaymentId(selectedPayment),
              derivedType: selectedPayment.type,
            });
            return;
          }
          Object.entries(rate).forEach(([_rateId, rateAmount]) => {
            const rateAmountCents = toCents(rateAmount);
            const newRate =
              rateAmountCents -
              (selectedPayment.additionalData?.discounts ?? []).reduce(
                (acc, val) => {
                  if (val.type === discountType.FIXED_AMOUNT)
                    return acc + val.amount;
                  if (val.type === discountType.PERCENTAGE)
                    return acc + (rateAmountCents * val.amount) / 100;

                  return acc;
                },
                0
              );

            lineItems.push({
              ...newLineItem,
              rate: newRate,
              total: amounts[amountId] ?? 0,
              quantity: (amounts[amountId] ?? 0) / newRate,
              isDerived: true,
              derivedPaymentId: getPaymentId(selectedPayment),
              derivedType: selectedPayment.type,
            });
          });
        }
      );
    }

    const sumOfQuantities = lineItems
      .filter((li) => newLineItem.id === li.id)
      .reduce((acc, val) => {
        return acc + val.quantity;
      }, 0);

    const hasPaymentOptions =
      !!Object.keys(
        filterPaymentMethods(
          paymentMethods,
          selectedPayments[getLineItemId(newLineItem)],
          []
        ) ?? {}
      ).length &&
      (sumOfQuantities === 0 || sumOfQuantities < newLineItem.originalQty);

    hasPaymentOptions && lineItems.push(newLineItem);
  });

  console.log("generateLineItems 3", invoice.lineItems);
  return lineItems.map((i) => {
    if (!i.isDerived) {
      const quantity = Math.max(
        (i.derivedLineItem ? i.quantity : i?.originalQty ?? 0) -
          lineItems.reduce((acc, val) => {
            if (val.type === i.type && val.isDerived) {
              return acc + val.quantity;
            }
            return acc;
          }, 0),
        0
      );

      return {
        ...i,
        quantity,
        total: quantity * i.rate + i.tax,
      };
    }
    return { ...i };
  });
};

export const getLineItemId = (lineItem: LineItem) =>
  `${lineItem.type}-${lineItem.description}`;

export const getDerivedLineItemId = (lineItem: LineItem) =>
  `${getLineItemId(lineItem)}-${lineItem.derivedPaymentId}`;

export const filterPaymentMethods = (
  paymentMethods: Partial<Record<string, IPayment[]>> | null,
  payments: IPayment[],
  lineItems: LineItem[]
) => {
  if (!paymentMethods) return null;
  const validPaymentsIds = new Set(
    (payments ?? []).map((p) => getPaymentId(p))
  );
  const filteredPayments: Partial<Record<string, IPayment[]>> = {};
  const derivedPayments = Object.groupBy(
    lineItems.filter((li) => li.isDerived && li.derivedPaymentId),
    (li) => li.derivedPaymentId!
  );

  Object.keys(paymentMethods).forEach((key) => {
    const paymentArray = paymentMethods[key as string];
    if (paymentArray) {
      const filteredArray = paymentArray.filter(
        (payment) => !validPaymentsIds.has(getPaymentId(payment))
      );
      if (filteredArray.length > 0) {
        filteredPayments[key as string] = filteredArray.map((payment) => {
          const existingPaymentsByLineItem =
            derivedPayments[getPaymentId(payment)];
          if (existingPaymentsByLineItem) {
            return {
              ...payment,
              additionalData: {
                ...payment.additionalData,
                unappliedAmount:
                  payment.additionalData.unappliedAmount! -
                  existingPaymentsByLineItem.reduce((acc, val) => {
                    return acc + val.rate * val.quantity;
                  }, 0),
              },
            };
          }
          return payment;
        });
      }
    }
  });
  return filteredPayments;
};

export const filterQuickPaymentMethods = (
  paymentMethods: Partial<Record<string, IPayment[]>> | null,
  _total: number
) => {
  if (!paymentMethods) return null;

  const filteredPayments: Partial<Record<string, IPayment[]>> = {};

  Object.keys(paymentMethods).forEach((key) => {
    const paymentArray = paymentMethods[key as string];
    if (paymentArray) {
      // const filteredArray = paymentArray.filter((payment) =>
      //   CARD_TYPES.includes(payment.type)
      // );
      const filteredArray = paymentArray;
      if (filteredArray.length > 0) {
        filteredPayments[key as string] = filteredArray;
      }
    }
  });
  return filteredPayments;
};

interface getLineItemFeesProps {
  lineItem: LineItem;
  forceCalculation?: boolean;
  clubPaysFees?: boolean;
  dismissPreFlightFee?: boolean;
  paymentMethod?: paymentTypes;
  clubId: string;
  locationId: string;
}
export const getLineItemFees = async ({
  lineItem,
  forceCalculation = false,
  paymentMethod,
  clubId,
  locationId,
}: getLineItemFeesProps) => {
  console.log("getLineItemFees", lineItem);
  if (!paymentMethod && !forceCalculation) {
    return {
      total: 0,
      fee: 0,
    };
  }
  const total = lineItem.total;

  if (total > 0) {
    const response = await calculateFees({
      paymentMethod,
      amount: total,
      clubId,
      locationId,
      dissmissPreFlightFee: false,
    }).catch((error) => {
      console.error("GET CALCULATED FEES ERROR", error);
      return error;
    });

    return {
      total,
      totalWithFees: response.totalCharge,
      fee: response.pilotFee,
    };
  }

  return {
    total,
    totalWithFees: total,
    fee: 0,
  };
};

interface getFeesForAmountProps {
  amount: number;
  forceCalculation?: boolean;
  clubPaysFees?: boolean;
  dismissPreFlightFee?: boolean;
  paymentMethod?: paymentTypes;
  clubId: string;
  locationId: string;
}
export const getFeesForAmount = async ({
  amount,
  forceCalculation = false,
  paymentMethod,
  clubId,
  locationId,
}: getFeesForAmountProps) => {
  console.log(
    "getFeesForAmount",
    amount,
    (forceCalculation = false),
    paymentMethod,
    clubId,
    locationId
  );
  if (!paymentMethod && !forceCalculation) {
    return {
      total: 0,
      fee: 0,
    };
  }
  const total = amount;

  if (total > 0) {
    const response = await calculateFees({
      paymentMethod,
      amount: total,
      clubId,
      locationId,
      dissmissPreFlightFee: false,
    }).catch((error) => {
      console.error("GET CALCULATED FEES ERROR", error);
      return error;
    });

    return {
      total,
      totalWithFees: response.totalCharge,
      fee: response.pilotFee,
    };
  }

  return {
    total,
    totalWithFees: total,
    fee: 0,
  };
};

export const fontStyle = {
  color: "white !important",
  fontSize: "14px",
  fontWeight: 400,
};

export const getPaymentId = (payment: IPayment) => payment?.token ?? payment.id;
