import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import CustomerCell from "./components/CustomerCell";

import { format } from "date-fns";
import { getProfileImageURL } from "src/features/utils";

export const TableActions = {
  SELECTED: "SELECTED",
};

export const getTableColumns = () => [
  {
    Header: "id",
    width: 50,
    accessor: "transactionNumber",
    Cell: ({ value }) => <IdCell id={value} />,
  },
  {
    Header: "date",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <DefaultCell value={format(value.toDate(), "eee, MMM do yyyy")} />
    ),
  },
  {
    Header: "member",
    accessor: ({ member, pilot }) => member?.displayName || pilot?.displayName,
    Cell: ({ row: { original } }) => {
      const { pilot, member } = original;
      return (
        <CustomerCell
          image={getProfileImageURL({ pilot })}
          color="dark"
          name={pilot?.displayName || member?.displayName}
        />
      );
    },
  },
  {
    Header: "Given By",
    accessor: ({ createdBy }) => createdBy?.name,
    Cell: ({ value }) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: "Total Credits",
    accessor: "credits",
    width: 100,
    Cell: ({ value }) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: "Remaining",
    accessor: "remainingCredits",
    width: 100,
    Cell: ({ value }) => {
      return <DefaultCell value={value} />;
    },
  },
];
