import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftSelect
import styles from "src/components/SoftAutocomplete/styles";
import { Autocomplete, TextField } from "@mui/material";

type softSelectType = {
  error?: boolean;
  success?: boolean;
  borderless?: boolean;
  ref?: any;
  size?: "small" | "medium" | "large";
  options: Array<{ label: string; value: string | number }>;
  label?: string;
  components?: React.ReactNode;
};

const SoftAutocomplete = forwardRef<HTMLDivElement, softSelectType>(
  ({ size = "medium", borderless, error = false, ...rest }, ref) => {
    return (
      <Autocomplete
        {...rest}
        ref={ref as any}
        sx={styles(size, borderless, error) as any}
        renderInput={(params) => <TextField {...params} />}
      />
    );
  }
);

// Typechecking props for the SoftSelect
const booleanPropType = PropTypes.bool as PropTypes.Validator<boolean>;
SoftAutocomplete.propTypes = {
  error: booleanPropType,
  success: booleanPropType,
};

export default SoftAutocomplete;
