export enum accountTabs {
  ACCOUNTS = "Accounts",
  FLIGHT_CREDITS = "Flight Credits",
  FUEL_REIMBURSEMENTS = "Fuel Reimbursements",
  DASHBOARD = "Dashboard",
  TRANSACTIONS = "Transactions",
  INVOICES = "Invoices",
  PAYMENTS = "Payments",
  MEMBERSHIPS = "Memberships",
}
export const ACCOUNTS_LIST_TAB = [
  accountTabs.ACCOUNTS,
  accountTabs.FLIGHT_CREDITS,
  // accountTabs.FUEL_REIMBURSEMENTS,
];
export const ACCOUNTS_TAB = [
  //accountTabs.DASHBOARD,
  accountTabs.TRANSACTIONS,
  accountTabs.INVOICES,
  accountTabs.PAYMENTS,
  accountTabs.FLIGHT_CREDITS,
  // accountTabs.FUEL_REIMBURSEMENTS,
  //accountTabs.MEMBERSHIPS,
];
