import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
// import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { query, orderBy, where } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { Modal } from "@mui/material";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfDay,
  endOfDay,
} from "date-fns";
import { getClubFlightCredits } from "src/features/club/collections";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { AddFlightCreditModal } from "src/modals/AddFlightCreditModal";
import { usePermissions } from "src/hooks/usePermissions";
import { useUser } from "src/features/user/UserProvider";

function FlightCredits({ userId }) {
  const navigate = useNavigate();
  const {
    selectedClubId,
    selectedClub,
    selectedLocation,
    selectedLocationId,
    clubUsers,
  } = useClubs();
  const { userId: currentUserId } = useUser();
  const { hasAccess } = usePermissions();

  if (!hasAccess(systemPermissions.VIEW_FLIGHT_CREDITS)) {
    userId = currentUserId;
  }
  // const [menu, setMenu] = useState(null);
  const [flightCreditsRows, setFlightCreditsRows] = useState([]);
  const [openAddNewFlightCredit, setOpenAddNewFlightCredit] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [csvData, setCsvData] = useState(null);
  // const [isFiltered, setIsFiltered] = useState(false);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const clubFlightCreditsCollectionRef = query(
    getClubFlightCredits(selectedClubId),
    where("createdAt", ">=", dateRange[0]),
    where("locationId", "==", selectedLocationId),
    orderBy("createdAt", "desc")
  );

  const { data: allFlightCredits, isDataLoaded: isFlightCreditsLoaded } =
    useRealtimeCollectionData(clubFlightCreditsCollectionRef, true);

  useEffect(() => {
    if (!allFlightCredits && !isFlightCreditsLoaded) {
      return;
    }

    console.log("allFlightCredits", allFlightCredits);

    const rows = [
      ...allFlightCredits
        .filter((credit) => (userId ? credit.userId === userId : true))
        .map((value) => ({
          ...value,
          credits: Math.round(value.credits * 10) / 10,
          remainingCredits: Math.round(value.remainingCredits * 10) / 10,
          member: clubUsers.get(value.userId),
        })),
    ].sort((a, b) => {
      return b.createdAt.toDate() - a.createdAt.toDate();
    });
    setFlightCreditsRows(rows);
  }, [allFlightCredits, isFlightCreditsLoaded, selectedLocationId, dateRange]);

  useEffect(() => {
    updateCsvData(tableRows);
  }, [tableRows]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      Date: row.createdAt
        ? format(row.createdAt.toDate(), "yyyy/MM/dd hh:mm:ss")
        : "---",
      Member: row.member?.displayName || row.pilot?.displayName || "---",
      "Total Credits": row?.credits,
      "Remaining Credits": row?.remainingCredits,
    }));

    setCsvData(csvDataFromRows);
  };

  const onRowSelected = (row) => {
    navigate({
      pathname: `/billing/flight-credits/${row.id}`,
    });
  };

  const addNewFlightCredit = () => {
    setOpenAddNewFlightCredit(true);
  };

  const handleNewFlightCreditClose = () => {
    setOpenAddNewFlightCredit(false);
  };

  const tableColumns = useMemo(() => getTableColumns(), []);
  const tableData = useMemo(() => flightCreditsRows, [flightCreditsRows]);

  const flightCreditUI = (
    <>
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox>
            <WithPermissions permissions={systemPermissions.ADD_FLIGHT_CREDITS}>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={addNewFlightCredit}
              >
                New Flight Credit
              </SoftButton>
            </WithPermissions>
          </SoftBox>
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            {/* <SoftButton
              variant={menu || isFiltered ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton> */}
            {/* {renderMenu} */}
            {csvData && (
              <SoftBox ml={1}>
                <SoftBox>
                  <CSVLink
                    data={csvData}
                    filename={`${selectedClub.name}-${
                      selectedLocation?.icao
                    }-billing_invoices-${format(new Date(), "MM/dd/yyyy")}.csv`}
                    target="_blank"
                  >
                    <SoftButton variant="outlined" color="dark">
                      <Icon>description</Icon>
                      &nbsp;export csv
                    </SoftButton>
                  </CSVLink>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <Card>
          {flightCreditsRows && flightCreditsRows.length > 0 && (
            <DataTable
              onRowSelect={onRowSelected}
              entriesPerPage={{ defaultValue: 50, entries: [10, 20, 50, 100] }}
              table={{
                columns: tableColumns,
                rows: tableData,
              }}
              onRowsChanged={(rows) => {
                setTableRows(rows.map((row) => row.original));
              }}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Modal
        open={openAddNewFlightCredit}
        onClose={handleNewFlightCreditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddFlightCreditModal
            userId={userId}
            handleClose={handleNewFlightCreditClose}
          />
        </SoftBox>
      </Modal>
    </>
  );

  if (userId) {
    return flightCreditUI;
  } else {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        {flightCreditUI}
        <Footer />
      </DashboardLayout>
    );
  }
}

export default FlightCredits;
