import { DeleteOutline } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import SoftCurrencyInputv2 from "src/components/SoftCurrencyInput/SoftCurrencyInputv2";
import SoftInput from "src/components/SoftInput";
import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import { IUseClub, LineItem, discountType } from "src/interfaces";
import {
  IQuickbookClass,
  IQuickbookItem,
} from "src/interfaces/quickbook.interface";
import { formatNumber } from "src/utils/number.utils";
import { QbkItem } from "./QbkItem";
import { QbkClass } from "./QbkClass";
import { applyDiscountToAmount } from "src/utils/invoice.utils";

interface EditableLineItemProps {
  lineItem: LineItem;
  hasQuickbooks: boolean;
  quickbooksItems?: IQuickbookItem[];
  quickbooksClasses?: IQuickbookClass[];
  disableEdit?: boolean;
  onLineItemEditted: (lineItem: LineItem) => void;
  onDelete: (lineItem: LineItem) => void;
}

export const EditableLineItem = ({
  lineItem,
  hasQuickbooks,
  quickbooksItems,
  quickbooksClasses,
  disableEdit,
  onLineItemEditted,
  onDelete,
}: EditableLineItemProps) => {
  const { selectedLocation } = useClubs() as IUseClub;

  const getTax = (isChecked: boolean, newLineItem?: LineItem) => {
    if (!isChecked) return 0;
    const thisNewLineItem = newLineItem ?? lineItem;
    const currentValue = thisNewLineItem.rate * thisNewLineItem.quantity;
    const discount =
      thisNewLineItem?.discount?.type === "PERCENTAGE"
        ? (thisNewLineItem.discount?.amount / 100) * currentValue
        : thisNewLineItem.discount?.amount ?? 0;
    const currentPrice = currentValue - discount;
    const taxPercentage = selectedLocation.preferences?.defaultTaxRate ?? 0;

    return (taxPercentage * currentPrice) / 100;
  };

  return (
    <>
      {hasQuickbooks && quickbooksItems && quickbooksItems?.length > 0 && (
        <td>
          <QbkItem
            lineItem={lineItem}
            quickbooksItems={quickbooksItems!}
            disabled={disableEdit}
            onLineItemEditted={(e) => onLineItemEditted(e as LineItem)}
          />
        </td>
      )}
      <td>
        <SoftInput
          placeholder="Description"
          id={`${lineItem.id}-description`}
          value={lineItem.description}
          disabled={disableEdit}
          onChange={(e) =>
            onLineItemEditted({
              ...lineItem,
              description: e.target.value,
            })
          }
        />
      </td>
      {hasQuickbooks && quickbooksClasses && quickbooksClasses?.length > 0 && (
        <td>
          <QbkClass
            lineItem={lineItem}
            disabled={disableEdit}
            quickbooksClasses={quickbooksClasses!}
            onLineItemEditted={(e) => onLineItemEditted(e as LineItem)}
          />
        </td>
      )}

      <td>
        <SoftInput
          placeholder="Quantity"
          disabled={disableEdit}
          id={`${lineItem.id}-quantity`}
          type="number"
          value={formatNumber(lineItem.quantity, 3)}
          onChange={(e) => {
            const qty = +e.target.value < 0 ? 0 : +e.target.value;
            const newLineItem = { ...lineItem, quantity: qty };
            onLineItemEditted({
              ...newLineItem,
              tax: getTax(!!lineItem.tax, newLineItem),
            });
          }}
        />
      </td>
      <td>
        <SoftCurrencyInputv2
          placeholder="Rate"
          cents
          id={`${lineItem.id}-rate`}
          value={lineItem.rate}
          disabled={disableEdit}
          allowNegative={true}
          onChangedValue={({ valueInCents }) => {
            const newLineItem = { ...lineItem, rate: valueInCents };
            onLineItemEditted({
              ...newLineItem,
              tax: getTax(!!lineItem.tax, newLineItem),
            });
          }}
        />
      </td>
      <td>
        <SoftInput
          placeholder="Discount"
          disabled={disableEdit}
          id={`${lineItem.id}-discount`}
          type="number"
          sx={{
            display: "inline-flex !important",
          }}
          value={formatNumber(lineItem.discount?.amount ?? 0, 3)}
          onChange={(e) => {
            let discountValue = +e.target.value;
            if (discountValue < 0) discountValue = 0;
            if (discountValue > 100) discountValue = 100;
            const discount = {
              amount: discountValue,
              type: discountType.PERCENTAGE,
            };

            const newLineItem = { ...lineItem, discount };
            onLineItemEditted({
              ...lineItem,
              discount,
              tax: getTax(!!lineItem.tax, newLineItem),
            });
          }}
          endAdornment={<span>%</span>}
        />
      </td>
      <td>
        {formatCurrency(
          lineItem.discount
            ? applyDiscountToAmount(
                lineItem.rate * lineItem.quantity,
                lineItem.discount
              )
            : lineItem.rate * lineItem.quantity,
          true
        )}
      </td>
      <td>
        <Checkbox
          disabled={disableEdit}
          checked={!!lineItem.tax}
          sx={{ background: disableEdit ? "#E9ECEF" : "white" }}
          onChange={(e) => {
            onLineItemEditted({
              ...lineItem,
              tax: getTax(e.target.checked),
            });
          }}
        />
      </td>
      <td style={{ padding: "0 12px" }}>
        <DeleteOutline
          onClick={() => !disableEdit && onDelete(lineItem)}
          sx={{
            color: "rgba(201, 0, 0, 1)",
            cursor: disableEdit ? "not-allowed" : "pointer",
            marginTop: "10px",
          }}
        />
      </td>
    </>
  );
};
