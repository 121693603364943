import { useEffect, useMemo, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// import { useCollection } from "react-firebase-hooks/firestore";
import { useClubs } from "src/features/club/ClubProvider";

import { subDays, endOfDay, startOfDay } from "date-fns";
import { formatCurrency } from "src/features/utils";
// import { getTransactionsCollection } from "src/features/transaction/collection";
// import { query, where } from "firebase/firestore";
import { SoftTab, SoftTabs } from "src/components/SoftTabs/SoftTabs";
import { ITransaction, IUseClub } from "src/interfaces";
import { useTabOrientation } from "src/hooks/useTabOrientation";
import {
  groupedTransactions,
  groupTransactionsByDate,
} from "../helpers/transaction.helper";

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Stack,
} from "@mui/material";

import credit from "src/assets/images/transactions/credit.svg";
import debit from "src/assets/images/transactions/debit.svg";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
import { getTransactions } from "src/services/transactions.api";
import { createSearchParams, useNavigate } from "react-router-dom";

interface TransactionsProps {
  userId: string;
  accountId: string | null;
}

export function Transactions({ userId }: TransactionsProps) {
  const navigate = useNavigate();
  const [transactionsData, setTransactionsData] = useState<
    groupedTransactions<ITransaction>[]
  >([]);

  const [dateRange, setDateRange] = useState([
    startOfDay(subDays(new Date(), 30)),
    endOfDay(new Date()),
  ]);

  const { selectedClubId, clubAccounts, selectedLocationId } =
    useClubs() as IUseClub;
  const [tabValue, setTabValue] = useState(0);

  const { tabsOrientation } = useTabOrientation();

  const selectedClubAccount = useMemo(() => {
    if (tabValue === 0) return "standard";
    return clubAccounts[tabValue - 1];
  }, [tabValue, clubAccounts]);

  // Call new api endpoint
  const { caller: getTransactionsFn } = usePromiseLoading({
    fn: getTransactions,
    throwError: true,
  });

  const fetchTransactions = async () => {
    const transactions = await getTransactionsFn({
      userId,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      startDate: dateRange[0],
      endDate: dateRange[1],
    });

    return transactions;
  };

  const loadTransactions = async () => {
    const transactions: Array<ITransaction> = [];

    transactions.push(
      ...(await fetchTransactions())
        .filter((t) =>
          selectedClubAccount === "standard"
            ? !t.accountId
            : t.accountId === selectedClubAccount.id
        )
        .map((t) => ({
          ...t,
          date: new Date(t.date),
        }))
    );

    setTransactionsData(
      groupTransactionsByDate(transactions).sort(
        (a, b) => b.date?.getTime() - a.date?.getTime()
      )
    );
  };

  const navigateToTransaction = (transaction: ITransaction) => {
    if (transaction.type === "INVOICE") {
      navigate({
        pathname: `/billing/invoice/${transaction.transactionId}`,
        search: createSearchParams({
          isNewInvoice: "true",
        }).toString(),
      });
    } else if (transaction.type === "PAYMENT") {
      navigate({
        pathname: `/billing/payments/${transaction.transactionId}`,
      });
    }
  };

  useEffect(() => {
    loadTransactions();
  }, [selectedClubAccount, selectedLocationId, dateRange]);

  return (
    <Card
      sx={{
        height: "100%",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={3}
        px={2}
        sx={{
          boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.1)",
        }}
      >
        <SoftBox display="inline-flex">
          <SoftTabs
            variation="secondary"
            orientation={tabsOrientation}
            value={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
            sx={{
              backgroundColor: "transparent",
              height: "fit-content",
            }}
          >
            <SoftTab label="Standard" />
            {clubAccounts.map((account) => (
              <SoftTab key={account.id} label={`${account.name}`} />
            ))}
          </SoftTabs>
        </SoftBox>
        <SoftBox display="flex" alignItems="flex-start">
          <SoftDateRangePicker
            value={dateRange}
            onChange={(newDates: any) => setDateRange(newDates)}
          />
        </SoftBox>
      </SoftBox>

      <SoftBox pt={3} pb={2} px={2} position="relative">
        {transactionsData.length === 0 && (
          <SoftBox p={2} mx={3} display="flex" justifyContent="center">
            <SoftTypography variant="caption" color="text" textAlign="center">
              <i>There are no transactions yet..</i>
            </SoftTypography>
          </SoftBox>
        )}

        {transactionsData.length > 0 && (
          <>
            <Stack
              position="absolute"
              direction="row"
              justifyContent="start"
              width="100%"
              pt={1}
              sx={{ top: "10px" }}
            >
              <SoftTypography
                fontSize="14px"
                fontWeight="bold"
                sx={{ padding: "0", mindWidth: "140px" }}
              >
                {transactionsData[0].label}
              </SoftTypography>
            </Stack>
            <Table
              sx={{
                minWidth: "32rem",
                paddingTop: 1,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />

                  <TableCell>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                    >
                      Total
                    </SoftTypography>
                  </TableCell>
                  <TableCell>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                    >
                      Balance
                    </SoftTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionsData.map((trd, i) => (
                  <>
                    {i !== 0 && (
                      <TableRow key={trd.label}>
                        <TableCell colSpan={4}>
                          <SoftTypography
                            fontSize="14px"
                            fontWeight="bold"
                            sx={{ padding: "20px 0" }}
                          >
                            {trd.label}
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                    )}
                    {trd.items
                      .sort((a, b) => b.date?.getTime() - a.date?.getTime())
                      .map((transaction) => (
                        <TableRow
                          key={transaction.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigateToTransaction(transaction)}
                        >
                          <TableCell width="100px">
                            <img
                              src={transaction.total >= 0 ? credit : debit}
                            />
                          </TableCell>
                          <TableCell width="auto">
                            <SoftTypography fontSize="14px" fontWeight="bold">
                              {transaction.description}
                            </SoftTypography>
                          </TableCell>
                          <TableCell width="150px">
                            <SoftTypography
                              fontSize="16px"
                              fontWeight="bold"
                              sx={{
                                color:
                                  transaction.type === "credit" ||
                                  transaction.type === "PAYMENT"
                                    ? "black"
                                    : "#A52F2F",
                              }}
                            >
                              <span>
                                {transaction.type === "debit" && "-"}{" "}
                                {formatCurrency(
                                  transaction.amount || transaction.total,
                                  true
                                )}
                              </span>
                            </SoftTypography>
                          </TableCell>
                          <TableCell width="50px">
                            {transaction.balance !== null &&
                            transaction.balance !== undefined
                              ? formatCurrency(transaction.balance, true)
                              : "TBD"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </SoftBox>
    </Card>
  );
}
