import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { useMemo } from "react";
import { formatCurrency } from "src/features/utils";
import { IClub, IInvoice, ILocation, PAID_INVOICE } from "src/interfaces";
import { formatTimestampOrString } from "src/utils/date.utils";
import { calculateInvoiceSummary } from "src/utils/invoice.utils";
import brandLogo from "src/assets/images/logo.png";
import paidLogo from "src/assets/images/paid-logo.png";
import { formatPhoneNumber } from "src/utils/number.utils";

interface InvoicePdfProps {
  invoice: IInvoice;
  location: ILocation;
  club: IClub;
  customNote?: string;
}

export const InvoicePdf = ({
  invoice,
  club,
  customNote,
  location,
}: InvoicePdfProps) => {
  const summary = useMemo(
    () => calculateInvoiceSummary(invoice, invoice?.clubPaysFees),
    [invoice?.lineItems]
  );
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoSection}>
            <Image style={styles.logo} src={club.logoUrl ?? brandLogo} />
            <Text stroke="3" style={styles.companyName}>
              {club.name}
            </Text>
            <Text style={styles.contactDetails}>{club.email}</Text>
            <Text style={styles.contactDetails}>
              {location.address.addressLine1}
            </Text>
            <Text style={styles.contactDetails}>
              {location.address.city}, {location.address.state}{" "}
              {location.address.zip}
            </Text>
            <Text style={styles.contactDetails}>
              Phone: {formatPhoneNumber(club.number)}
            </Text>
            <Text style={styles.contactDetails}>Email: {club.email}</Text>
            <Text style={styles.contactDetails}>Website: {club.website}</Text>
          </View>
          <View style={styles.invoiceDetails}>
            <Text style={styles.invoiceTitle}>Invoicing</Text>
            <Text>Invoice #: {invoice.transactionNumber}</Text>
            <Text>
              Invoice date:{" "}
              {formatTimestampOrString(invoice.createdAt, "EEE, MMM do yyyy")}
            </Text>
            {/* <Text>Terms: {invoice.terms}</Text> */}
            <Text>
              Due date:{" "}
              {formatTimestampOrString(invoice.dueDate, "EEE, MMM do yyyy")}
            </Text>
          </View>
        </View>
        <View style={styles.billToSection}>
          <View style={styles.billTo}>
            <Text style={styles.companyName}>Billed to:</Text>
            <Text stroke="3">{invoice.member.displayName}</Text>
            <Text>{invoice.member.email}</Text>
          </View>
        </View>
        <View style={styles.tableContainer}>
          <View style={styles.tableHeader}>
            <View style={styles.tableColHeader}>
              <Text>Description</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Quantity</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Rate</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Discount</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Tax</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text>Amount</Text>
            </View>
          </View>
          {invoice.lineItems.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text>{item.description}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{formatCurrency(item.rate, true)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{item.discount?.amount}%</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{formatCurrency(item.tax, true)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text>{formatCurrency(item.total, true)}</Text>
              </View>
            </View>
          ))}
        </View>
        {customNote && (
          <View style={styles.customerMessage}>
            <Text>{customNote}</Text>
          </View>
        )}
        <View style={styles.summary}>
          <View style={styles.summaryItem}>
            <Text>Discounts:</Text>
            <Text>{formatCurrency(summary.discount, true)}</Text>
          </View>
          <View style={styles.summaryItem}>
            <Text>Taxes:</Text>
            <Text>{formatCurrency(summary.tax, true)}</Text>
          </View>
          <View style={styles.summaryItem}>
            <Text>Fees:</Text>
            <Text>{formatCurrency(summary.fees, true)}</Text>
          </View>
          <View style={[styles.summaryItem, styles.summaryTotal]}>
            <Text>Total:</Text>
            <Text>{formatCurrency(summary.total, true)}</Text>
          </View>
          {PAID_INVOICE.includes(invoice.status) && (
            <View style={styles.paidLogoContainer}>
              <Image style={styles.paidLogo} src={paidLogo} />
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logoSection: {
    flexDirection: "column",
    width: "60%",
  },
  logo: {
    width: 100,
    marginBottom: 10,
  },
  paidLogoContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "40%",
    marginBottom: 5,
  },
  paidLogo: {
    width: 100,
    marginTop: 10,
  },
  companyDetails: {
    marginBottom: 10,
  },
  invoiceTitle: {
    fontSize: 18,
    fontWeight: "bold",
  },
  companyName: {
    fontSize: 12,
    fontWeight: "bold",
  },
  contactDetails: {
    fontSize: 10,
    marginBottom: 4,
  },
  invoiceDetails: {
    textAlign: "right",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  billToSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  billTo: {
    width: "45%",
  },
  tableContainer: {
    paddingBottom: 10,
    borderBottom: "1px solid lightgrey",
  },
  tableHeader: {
    flexDirection: "row",
    borderBottom: "1px solid lightgrey",
    fontWeight: 600,
    padding: 5,
  },
  tableRow: {
    flexDirection: "row",
    padding: 5,
  },
  tableColHeader: {
    width: "16.67%",
  },
  tableCol: {
    width: "16.67%",
  },
  tableCell: {
    padding: 5,
  },
  summary: {
    marginTop: 20,
    flexDirection: "column",
    alignItems: "flex-end",
  },
  summaryItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "40%",
    marginBottom: 5,
  },
  summaryTotal: {
    borderTop: "3px solid black",
    paddingTop: 10,
    fontSize: 14,
    fontWeight: "bold",
  },
  customerMessage: {
    marginTop: 20,
  },
});
