// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

function HoursCell({ value, suffix }) {
  return (
    <SoftTypography
      variant="caption"
      textTransform="capitalize"
      fontWeight="medium"
      color="text"
    >
      {value}
      {suffix && (
        <SoftTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </SoftTypography>
      )}
    </SoftTypography>
  );
}

// Setting default values for the props of DefaultCell
HoursCell.defaultProps = {
  suffix: "hrs",
};

// Typechecking props for the DefaultCell
HoursCell.propTypes = {
  value: PropTypes.number.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default HoursCell;
