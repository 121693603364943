import { useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import { Grid, Icon, Link, Modal } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import { isDev } from "src/features/utils";
import logoQuickbooks from "src/assets/images/small-logos/logo-quickbooks.svg";
import { useClubs } from "src/features/club/ClubProvider";
import SoftButton from "src/components/SoftButton";
import { AddPaymentModal } from "src/modals/AddPaymentModal";
import { resendPaymentRequest } from "src/services/payments.api";

function Header({ payment }) {
  const { selectedClub, clubUsers, selectedLocationId, selectedClubId } =
    useClubs();
  const [openAddNewPayment, setOpenAddNewPayment] = useState(false);
  const user = clubUsers.get(payment?.userId);

  const addNewPayment = () => {
    setOpenAddNewPayment(true);
  };

  const handleNewPaymentClose = () => {
    setOpenAddNewPayment(false);
  };

  const onResendpayment = async () => {
    console.log(
      "Resend payment",
      selectedClubId,
      selectedLocationId,
      payment?.id
    );
    await resendPaymentRequest({
      clubId: selectedClubId,
      locationId: selectedLocationId,
      id: payment?.id,
    });
  };

  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <SoftTypography variant="h4" fontWeight="medium">
            {selectedClub?.name}
          </SoftTypography>
          {selectedClub?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {selectedClub.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
            {user?.displayName && (
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billed to: {user.displayName}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.email && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user.email}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.address && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user?.address?.addressLine1}
                  <br />
                  {user?.address?.addressLine2 && (
                    <>
                      {user?.address?.addressLine2}
                      <br />
                    </>
                  )}
                  {user?.address?.city}, {user?.address?.state},{" "}
                  {user?.address?.zip}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Payment - #{payment?.transactionNumber}
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                ID -{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {payment?.id}
                </span>
              </SoftTypography>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
                pt={1}
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {payment?.createdAt
                    ? format(payment.createdAt.toDate(), "eee, MMM do yyyy")
                    : "N/A"}
                </span>
              </SoftTypography>
              {payment?.qboPaymentId && (
                <SoftBox
                  mt={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Link
                    href={`https://app.${
                      isDev ? "sandbox." : ""
                    }qbo.intuit.com/app/payment?txnId=${payment?.qboPaymentId}`}
                    target="_blank"
                    lineHeight={1}
                  >
                    <SoftBox component="img" src={logoQuickbooks} width={35} />
                  </Link>
                  <SoftTypography
                    component="p"
                    variant="button"
                    fontWeight="bold"
                    color="text"
                  >
                    Quickbooks No. -{" "}
                    <span
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {payment?.qboPaymentId}
                    </span>
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
            <SoftBox>
              {payment?.status === "REQUESTED" && (
                <>
                  <SoftButton
                    color="info"
                    variant="text"
                    startIcon={<Icon>edit</Icon>}
                    onClick={addNewPayment}
                    style={{ marginLeft: "10px" }}
                  >
                    Edit
                  </SoftButton>
                  <SoftButton
                    color="info"
                    variant="text"
                    startIcon={<Icon>send</Icon>}
                    onClick={onResendpayment}
                    style={{ marginLeft: "10px" }}
                  >
                    Resend Request
                  </SoftButton>
                </>
              )}
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <Modal
        open={openAddNewPayment}
        onClose={handleNewPaymentClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddPaymentModal
            payment={payment}
            handleClose={handleNewPaymentClose}
          />
        </SoftBox>
      </Modal>
    </SoftBox>
  );
}

Header.propTypes = {
  payment: PropTypes.object,
  onResendpayment: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  onCancelpayment: PropTypes.func,
};

export default Header;
