import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function FlightCreditSummary({ flightCredit }) {
  return (
    <>
      <SoftBox mb={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Flight Credit Summary
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftBox>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Amount:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium" color="text">
            {flightCredit?.credits.toFixed(2)} hrs
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mt={3}>
        <SoftBox>
          <SoftTypography variant="body1" fontWeight="light" color="text">
            Remaining:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body1" fontWeight="medium">
            {flightCredit?.remainingCredits.toFixed(2) || 0} hrs
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

FlightCreditSummary.propTypes = {
  flightCredit: PropTypes.object.isRequired,
};

export default FlightCreditSummary;
