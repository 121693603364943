import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

import SoftBox from "src/components/SoftBox";
import { useClubs } from "src/features/club/ClubProvider";

import { ILocationUser, IUseClub } from "src/interfaces";
import { useNavigate } from "react-router-dom";
import DataTable from "src/components/Tables/DataTable";
import { getDocs, query, where } from "firebase/firestore";
import { getClubFlightCredits } from "src/features/club/collections";
import { getTableColumns } from "./tableColumns";

type rowTableType = ILocationUser & {
  showUser: boolean;
  accountBalance?: number;
} & {
  accountId?: string;
};

export function FlightCreditsList() {
  const navigate = useNavigate();

  const { locationUsers, selectedClubId } = useClubs() as IUseClub;
  const [tableRows, setTableRows] = useState<Array<rowTableType>>([]);
  // const [locationUsers, setLocationUsers] = useState([]);

  const addUserAccountBalance = async () => {
    const usersWithBalance = await Promise.all(
      locationUsers.map(async (user) => {
        let credits: any[] = [];

        const invoicesRef = await getDocs(
          query(
            getClubFlightCredits(selectedClubId),
            where("userId", "==", user.id)
          )
        );
        credits = invoicesRef.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        const remainingCreditsBalance = credits.reduce((acc, credit) => {
          return acc + (credit?.remainingCredits || 0);
        }, 0);

        const accountBalance = Math.round(remainingCreditsBalance * 10) / 10;

        if (credits.length > 0) {
          return {
            ...user,
            accountBalance: accountBalance ?? 0,
            showUser: true,
          };
        } else {
          return {
            ...user,
            accountBalance: accountBalance ?? 0,
            showUser: false,
          };
        }
      })
    );

    setTableRows(
      usersWithBalance.sort((a, b) => b.accountBalance - a.accountBalance)
    );
  };

  useEffect(() => {
    addUserAccountBalance();
  }, [locationUsers]);

  const onRowSelected = (row: any) => {
    navigate(`/billing/accounts/${row.id}?tab=Flight%20Credits`);
  };

  const getTableRows = () => {
    return tableRows;
  };

  return (
    <Card>
      <SoftBox mt={2}>
        {!!tableRows?.length && (
          <DataTable
            onRowSelect={onRowSelected}
            table={{
              columns: getTableColumns(),
              rows: getTableRows(),
            }}
            entriesPerPage={{
              defaultValue: 10,
              entries: [10, 20, 50, 100],
            }}
            canSearch
          />
        )}
      </SoftBox>
    </Card>
  );
}
