import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftAvatar from "src/components/SoftAvatar";
import SoftButton from "src/components/SoftButton";

// Data
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubsCollection } from "src/features/club/collections";
import { getFunctions, httpsCallable } from "firebase/functions";

import QuickbooksClassInput from "src/components/QuickbooksClassInput";

// Images
import logoQuickbooks from "src/assets/images/small-logos/logo-quickbooks.svg";
import { TextField } from "@mui/material";
import SoftAutocomplete from "src/components/SoftAutocomplete";
// import { Autocomplete, TextField } from '@mui/material';

let qbWindow;

function Integrations({ qbPrefs, qbItems, qbAccounts }) {
  const { selectedClub, selectedClubId } = useClubs();
  const { quickbooks } = selectedClub;
  const [quickbooksActive, setQuickbooksActive] = useState(false);
  const [showQuickbooks, setShowQuickbooks] = useState(true);
  const [classesEnabled, setClassesEnabled] = useState(false);
  const [classValues, setClassValues] = useState(
    selectedClub?.quickbooks?.classes || {}
  );
  const [itemValues, setItemValues] = useState(
    selectedClub?.quickbooks?.items || {}
  );
  const [accountValues, setAccountValues] = useState(
    selectedClub?.quickbooks?.accounts || {}
  );
  const functions = getFunctions();

  useEffect(() => {
    if (selectedClub?.quickbooks) {
      setItemValues(selectedClub.quickbooks.items);
      setClassValues(selectedClub.quickbooks.classes);
      setAccountValues(selectedClub.quickbooks.accounts);
    }
  }, [selectedClub, qbPrefs, qbItems, qbAccounts]);

  const updatePreference = async (key, value) => {
    quickbooks[key] = value;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        quickbooks,
      });
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const saveQboSettings = async () => {
    quickbooks.classes = classValues;
    quickbooks.items = itemValues;
    quickbooks.accounts = accountValues;
    try {
      await updateDoc(doc(getClubsCollection(), selectedClubId), {
        quickbooks,
      });
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const handleSetQuickbooksActive = () => {
    updatePreference("enabled", !quickbooksActive);
    setQuickbooksActive(!quickbooksActive);
  };
  const toggleShowQuickbooks = () => setShowQuickbooks(!showQuickbooks);

  const [club, clubIsLoading] = useDocument(
    doc(getClubsCollection(selectedClubId), selectedClubId)
  );

  useEffect(() => {
    if (club?.data()?.quickbooks?.enabled) {
      setQuickbooksActive(club.data().quickbooks.enabled);
    }
  }, [club]);

  const connectQuickbooks = async () => {
    const parameters = "location=1,width=800,height=650";
    qbWindow = window.open(
      `${
        import.meta.env.VITE_FIREBASE_URL
      }/quickbooksAuth?clubId=${selectedClubId}`,
      "connectPopup",
      parameters
    );
  };

  const syncQuickbooksCustomers = async () => {
    const syncCustomers = httpsCallable(
      functions,
      "syncQuickbooksCustomers-default"
    );
    syncCustomers({ clubId: selectedClubId })
      .then((result) => {
        console.log("result", result.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    if (clubIsLoading) return;
    if (
      club &&
      qbWindow &&
      club.data()?.quickbooks?.relmId &&
      club.data()?.quickbooks?.tokens
    ) {
      qbWindow.close();
    }
  }, [club]);

  const disconnectQuickbooks = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "Your data will no longer sync with Quickbooks!",
        showCancelButton: true,
        confirmButtonText: "Yes, disconnect me!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await updateDoc(club.ref, {
            quickbooks: deleteField(),
          });
          newSwal.fire(
            "Done!",
            "Your Quickbooks account has been disconnected.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          newSwal.fire("Cancelled", "The connection is safe :)", "error");
        }
      });
  };

  useEffect(() => {
    if (
      qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxn ||
      qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxnLine
    ) {
      setClassesEnabled(true);
    }
  }, [qbPrefs]);

  const onChangeQuickbooksClass = (ev, name, newValue) => {
    setClassValues({ ...classValues, [name]: newValue });
  };

  const onChangeQuickbooksItem = (ev, name) => {
    setItemValues({ ...itemValues, [name]: ev });
  };

  const onChangeQuickbooksAccount = (ev, name) => {
    setAccountValues({ ...accountValues, [name]: ev });
  };

  return (
    <Card id="accounts">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Integrations</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Here you can setup and manage your integration settings.
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={2} pb={3} px={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <SoftBox display="flex" alignItems="center">
            <SoftAvatar
              src={logoQuickbooks}
              alt="Quickbooks logo"
              variant="rounded"
            />
            <SoftBox ml={2}>
              <SoftTypography variant="h5" fontWeight="medium">
                Quickbooks
              </SoftTypography>
              {showQuickbooks ? (
                <SoftBox display="flex" alignItems="flex-end">
                  <SoftTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    onClick={toggleShowQuickbooks}
                  >
                    Show less
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    color="text"
                    sx={{ lineHeight: 0 }}
                  >
                    <Icon fontSize="small">expand_less</Icon>
                  </SoftTypography>
                </SoftBox>
              ) : (
                <SoftBox display="flex" alignItems="flex-end">
                  <SoftTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                    onClick={toggleShowQuickbooks}
                  >
                    Show more
                  </SoftTypography>
                  <SoftTypography
                    variant="button"
                    color="text"
                    sx={{ lineHeight: 0 }}
                  >
                    <Icon fontSize="small">expand_more</Icon>
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <SoftBox lineHeight={0} mx={2}>
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {quickbooksActive ? "Enabled" : "Disabled"}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={1}>
              <Switch
                checked={quickbooksActive}
                onChange={handleSetQuickbooksActive}
              />
            </SoftBox>
          </SoftBox>
        </SoftBox>
        {showQuickbooks ? (
          <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
            {club && !club.data()?.quickbooks?.tokens ? (
              <SoftTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                Authenticate you Quickbooks account to sync your data.
              </SoftTypography>
            ) : (
              <SoftBox>
                {quickbooksActive && (
                  <SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Sync Customers
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftButton
                          color="info"
                          fullWidth
                          onClick={syncQuickbooksCustomers}
                        >
                          Sync
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Aircraft Rental
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(newValue, "aircraftRental");
                          }}
                          value={itemValues?.aircraftRental}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Aircraft Rental
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.aircraftRental}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(
                                e,
                                "aircraftRental",
                                value
                              )
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Instruction Fees
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(newValue, "instructionFees");
                          }}
                          value={itemValues?.instructionFees}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Instruction Fees
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.instructionFees}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(
                                e,
                                "instructionFees",
                                value
                              )
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Ground Instruction
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(
                              newValue,
                              "instructionFeesGround"
                            );
                          }}
                          value={itemValues?.instructionFeesGround}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Ground Instruction
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.instructionFeesGround}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(
                                e,
                                "instructionFeesGround",
                                value
                              )
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Fuel Reimbursements
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(
                              newValue,
                              "fuelReimbursements"
                            );
                          }}
                          value={itemValues?.fuelReimbursements}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Fuel Reimbursements
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.fuelReimbursements}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(
                                e,
                                "fuelReimbursements",
                                value
                              )
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Pre-Heat Fees
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(newValue, "preHeatFees");
                          }}
                          value={itemValues?.preHeatFees}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Pre-Heat Fees
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.preHeatFees}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(e, "preHeatFees", value)
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Membership Fees
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(newValue, "membershipFees");
                          }}
                          value={itemValues?.membershipFees}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Membership Fees
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.membershipFees}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(
                                e,
                                "membershipFees",
                                value
                              )
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Item for Credit Memo
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbItems
                            .filter((item) => item.Active)
                            .map((item) => ({
                              label: item.FullyQualifiedName,
                              value: item.Id,
                              parent: {
                                name: item.ParentRef?.name,
                                value: item.ParentRef?.value,
                              },
                            }))}
                          // groupBy={(option) => option.parent?.name}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksItem(newValue, "creditMemo");
                          }}
                          value={itemValues?.creditMemo}
                        />
                      </SoftBox>
                    </SoftBox>
                    {classesEnabled && (
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                        lineHeight={1}
                      >
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          QuickBooks Class for Credit Memo
                        </SoftTypography>
                        <SoftBox ml={2} mr={1} width={300}>
                          <QuickbooksClassInput
                            value={classValues?.creditMemo}
                            onChange={(e, value) =>
                              onChangeQuickbooksClass(e, "creditMemo", value)
                            }
                          />
                        </SoftBox>
                      </SoftBox>
                    )}

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Checking Account for Expenses
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbAccounts?.map((account) => ({
                            label: account.Name,
                            value: account.Id,
                          }))}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksAccount(newValue, "expenses");
                          }}
                          value={accountValues?.expenses}
                        />
                      </SoftBox>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        QuickBooks Accounts Receivable Account
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftAutocomplete
                          options={qbAccounts?.map((account) => ({
                            label: account.Name,
                            value: account.Id,
                          }))}
                          getOptionLabel={(option) => option.label}
                          sx={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} />}
                          onChange={(e, newValue) => {
                            onChangeQuickbooksAccount(
                              newValue,
                              "accountsReceivable"
                            );
                          }}
                          value={accountValues?.accountsReceivable}
                        />
                      </SoftBox>
                    </SoftBox>

                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      />
                      <SoftBox ml={2} mr={1} width={300}>
                        <SoftButton
                          color="info"
                          fullWidth
                          onClick={saveQboSettings}
                        >
                          Save
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                )}
              </SoftBox>
            )}
            <SoftBox
              bgColor="grey-100"
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
              my={3}
              py={1}
              pl={{ xs: 1, sm: 2 }}
              pr={1}
            >
              <SoftTypography variant="button" fontWeight="medium" color="text">
                Connected account
              </SoftTypography>
              <SoftBox
                display="flex"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                  <SoftTypography variant="button" fontWeight="medium">
                    {club && club.data()?.quickbooks?.tokens
                      ? "QB Account here"
                      : "Not connected"}
                  </SoftTypography>
                </SoftBox>
                {club && !club.data()?.quickbooks?.tokens ? (
                  <SoftButton
                    color="primary"
                    variant="contained"
                    onClick={connectQuickbooks}
                    size="small"
                  >
                    Connect Quickbooks
                  </SoftButton>
                ) : (
                  <SoftButton
                    color="error"
                    variant="contained"
                    onClick={disconnectQuickbooks}
                    size="small"
                  >
                    Disonnect Quickbooks
                  </SoftButton>
                )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        ) : null}
        {/* <Divider /> */}
      </SoftBox>
    </Card>
  );
}

Integrations.propTypes = {
  qbPrefs: PropTypes.object,
  qbAccounts: PropTypes.array,
  qbItems: PropTypes.array,
};

export default Integrations;
