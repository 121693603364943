import { useEffect, useState } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import { isToday, isYesterday, startOfYesterday } from "date-fns";
import { query, orderBy, where } from "firebase/firestore";

import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import { getClubInvoices } from "src/features/club/collections";

function TodaysEarnings() {
  const { selectedClubId } = useClubs();
  const [todaysEarnings, setTodaysEarnings] = useState(0);
  const [yesterdaysEarnings, setYesterdaysEarnings] = useState(0);
  const [dodGrowth, setDodGrowth] = useState(0);

  const clubsTransactionsCollectionRef = query(
    getClubInvoices(selectedClubId),
    where("createdAt", ">", startOfYesterday()),
    orderBy("createdAt", "desc")
  );
  const { data: allTransactions, isDataLoaded: transactionsLoaded } =
    useRealtimeCollectionData(clubsTransactionsCollectionRef, true);

  useEffect(() => {
    if (allTransactions && transactionsLoaded) {
      const filteredTransactions = allTransactions.filter((transaction) => {
        const transactionDate = transaction.createdAt.toDate();
        return isToday(transactionDate);
      });

      const yesterdaysFilteredTransactions = allTransactions.filter(
        (transaction) => {
          const transactionDate = transaction.createdAt.toDate();
          return isYesterday(transactionDate);
        }
      );

      let earnings = 0;
      filteredTransactions.forEach((element) => {
        if (element.type === "accountFunding" || element.type === "deposit")
          return;

        earnings += (element?.totalAmount ?? 0) / 100;
      });
      setTodaysEarnings(earnings);

      let earningsYesterday = 0;
      yesterdaysFilteredTransactions.forEach((element) => {
        earningsYesterday += (element?.totalAmount ?? 0) / 100;
      });
      setYesterdaysEarnings(earningsYesterday);
    }
  }, [allTransactions]);

  useEffect(() => {
    const growth =
      ((todaysEarnings - yesterdaysEarnings) / yesterdaysEarnings) * 100;
    setDodGrowth(growth);
  }, [todaysEarnings, yesterdaysEarnings]);

  return (
    <SoftBox mb={3}>
      <MiniStatisticsCard
        title={{
          text: "today's earnings",
          fontWeight: "bold",
        }}
        count={`$${Math.round(todaysEarnings)}`}
        percentage={{
          color: dodGrowth >= 0 ? "success" : "error",
          text: `${dodGrowth >= 0 ? "+" : ""}${
            dodGrowth ? Math.round(dodGrowth) : 0
          }%`,
        }}
        icon={{
          color: "info",
          component: "paid",
        }}
      />
    </SoftBox>
  );
}

export default TodaysEarnings;
