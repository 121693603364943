import { useState, useEffect, useMemo } from "react";
import toastr from "toastr";

import { Grid, Divider, Stack } from "@mui/material";

import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import PropTypes from "prop-types";
import * as yup from "yup";
import CurrencyFormat from "react-currency-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import {
  calculateFees,
  createPayment,
  chargeCreditCard,
  getPaymentMethods,
  chargeAch,
  sendPaymentRequest,
  // submitPayment,
} from "src/services/payments.api";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";

import { ThreeDots } from "react-loader-spinner";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import {
  AccountBalanceWalletOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import ConveyorBelt from "src/assets/images/icons/conveyor_belt.svg";
import PaymentOptions, { RenderPayment } from "../PaymentModal/PaymentOptions";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
// import {
//   CARD_TYPES,
//   filterPaymentMethods,
//   filterQuickPaymentMethods,
// } from "./payment.configs";
import { paymentTypes } from "src/interfaces";
import {
  CARD_TYPES,
  filterQuickPaymentMethods,
} from "../PaymentModal/payment.configs";
import { openModal$ } from "../modalConfiguration";
import SoftTextareaAutosize from "src/components/SoftTextareaAutosize";

const paymentMethodTypes = [
  {
    id: "1",
    value: paymentTypes.CARD_FWD,
    label: "Credit Card",
  },
  {
    id: "2",
    value: paymentTypes.ACH_FWD,
    label: "ACH Payment",
  },
  {
    id: "3",
    value: paymentTypes.CASH,
    label: "Cash",
  },
  {
    id: "4",
    value: paymentTypes.CHECK,
    label: "Check",
  },
  {
    id: "5",
    value: paymentTypes.LOAN,
    label: "Loan Disbursement",
  },
  {
    id: "6",
    value: paymentTypes.OTHER,
    label: "Other Deposit",
  },
  {
    id: "7",
    value: "PAYMENT_REQUEST",
    label: "Request Payment",
  },
];

const schema = yup
  .object({
    date: yup.date().required("A transaction date is required."),
    member: yup.object().required("A member must be selected."),
  })
  .required();

export function AddPaymentModal({ handleClose, payment }) {
  const { selectedClubId, selectedLocationId, clubUsers, clubAccounts } =
    useClubs();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState([]);
  const [totalFees, setTotalFees] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [clubPaysFees, setClubPaysFees] = useState(false);
  const [simplePayment, setSimplePayment] = useState(null);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      date: payment?.date?.toDate?.() ?? new Date(),
      account: payment?.account ?? { label: "Standard", value: "" },
      amount: payment?.amount ? payment?.amount / 100 : null,
      member: payment?.userId
        ? {
            label: clubUsers.get(payment?.userId)?.displayName,
            value: payment?.userId,
          }
        : null,
      description: payment?.description,
      paymentMethod: payment?.type
        ? paymentMethodTypes.find((p) => p.value === payment.type)
        : null,
    },
    resolver: yupResolver(schema),
  });

  const selectedMember = watch("member");
  const amount = watch("amount");
  const selectedPaymentMethodType = watch("paymentMethod");

  useEffect(() => {
    const memberOptions = Array.from(clubUsers?.values?.()).map((member) => ({
      value: member.uid,
      label: member.displayName,
    }));

    setMembers(memberOptions);
  }, [clubUsers]);

  const updateTotalAmount = async (amount) => {
    const calculatedFees = await calculateFees({
      paymentMethod: selectedPaymentMethodType?.value ?? paymentTypes.ACCOUNT,
      amount: parseFloat(amount) * 100,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      dissmissPreFlightFee: true,
    });
    console.log("calculatedFees", calculatedFees);
    setClubPaysFees(calculatedFees?.clubPaysFees);
    setTotalFees(calculatedFees?.applicationFee);
    setTotalAmount(calculatedFees?.totalCharge);
  };

  useEffect(() => {
    updateTotalAmount(amount);
  }, [amount, selectedPaymentMethodType]);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    console.log("data", data, simplePayment);
    let paymentResult;
    const amount = parseFloat(data.amount) * 100;

    switch (data.paymentMethod?.value) {
      case paymentTypes.CARD_FWD:
        console.log("Card Forward");
        paymentResult = await chargeCreditCard({
          amount,
          clubId: selectedClubId,
          locationId: selectedLocationId,
          userId: data.member.value,
          paymentMethodId: simplePayment.token,
          description: data.description ?? "Credit Card Payment",
          dissmissPreFlightFee: true,
          date: data.date,
        }).catch((error) => {
          console.error("error", error);
          toastr.error("Error processing credit card payment.");
          setIsSubmitting(false);
          // setPaymentError(error);
        });
        break;
      case paymentTypes.ACH_FWD:
        console.log("ACH Forward");
        paymentResult = await chargeAch({
          amount,
          clubId: selectedClubId,
          locationId: selectedLocationId,
          userId: data.member.value,
          paymentMethodId: simplePayment.token,
          description: data.description ?? "ACH Payment",
          dissmissPreFlightFee: true,
          date: data.date,
        }).catch((error) => {
          console.error("error", error);
          toastr.error("Error processing ACH payment.");
          setIsSubmitting(false);
          // setPaymentError(error);
        });
        break;
      case "PAYMENT_REQUEST":
        console.log("Payment Request");
        paymentResult = await sendPaymentRequest({
          id: payment?.id,
          amount,
          clubId: selectedClubId,
          locationId: selectedLocationId,
          userId: data.member.value,
          acceptedPaymentMethods: data.acceptedPaymentMethods,
          description: data.description ?? "Payment Request",
          dissmissPreFlightFee: true,
          date: data.date,
        }).catch((error) => {
          console.error("error", error);
          toastr.error("Error sending payment request.");
          setIsSubmitting(false);
          // setPaymentError(error);
        });
        break;
      default:
        paymentResult = await createPayment({
          amount,
          totalAmount: totalAmount,
          unappliedAmount: data.amount * 100,
          fees: totalFees,
          date: data.date,
          clubId: selectedClubId,
          locationId: selectedLocationId,
          userId: data.member.value,
          accountId: data.account.value !== "" ? data.account.value : undefined,
          status: "SUCCESSFUL",
          type: data.paymentMethod.value,
          description: data.description ?? "Standard Payment",
          clubPaysFees,
        }).catch((error) => {
          console.error("error", error);
          toastr.error("Error adding payment.");
          setIsSubmitting(false);
          // setPaymentError(error);
        });
        break;
    }

    if (
      paymentResult?.status === "SUCCESSFUL" ||
      paymentResult?.status === "REQUESTED"
    ) {
      console.log("paymentResult", paymentResult);
      toastr.success(
        `Payment ${
          paymentResult?.status === "REQUESTED" ? "requested" : "added"
        } successfully.`
      );
      handleClose();
    }
  };

  const {
    caller,
    data: paymentMethodsData,
    isLoading: isLoadingPaymentMethods,
  } = usePromiseLoading({ fn: getPaymentMethods });

  const getPaymentMethodsFn = () =>
    caller({
      clubId: selectedClubId,
      locationId: selectedLocationId,
      userId: selectedMember?.value,
    });

  useEffect(() => {
    if (selectedMember) {
      getPaymentMethodsFn();
    } else {
      setSimplePayment(null);
    }
  }, [selectedMember]);

  const paymentMethods = useMemo(() => {
    return paymentMethodsData
      ? Object.entries(
          Object.groupBy(
            paymentMethodsData,
            ({ type, additionalData, displayName }) =>
              additionalData?.accountId ?? type === paymentTypes.ACCOUNT
                ? `${displayName}-subtype:${paymentTypes.ACCOUNT}`
                : type
          )
        ).reduce((acc, val) => {
          const [key, value] = val;

          let newKey = "Other";
          if (CARD_TYPES.includes(key)) {
            newKey = "Credit Card";
          } else if (key === paymentTypes.PAYMENT) {
            newKey = "Standard";
          } else if (key.includes(`-subtype:${paymentTypes.ACCOUNT}`)) {
            const [originalKey] = key.split(`-subtype:${paymentTypes.ACCOUNT}`);
            newKey = originalKey;
          } else if (key === paymentTypes.FUEL_REIMBURSEMENT) {
            newKey = "Fuel Reimbursements";
          } else if (key === paymentTypes.FLIGHT_CREDIT) {
            newKey = "Flight Credit";
          } else {
            const account = clubAccounts.find((club) => club.id === key);
            newKey = account?.name ?? newKey;
          }

          acc[newKey] = value;
          return acc;
        }, {})
      : null;
  }, [paymentMethodsData]);

  const handleSelectedPayment = (payment) => {
    if (payment.id === "ADD_CARD") {
      openModal$.next({
        modalName: "ADD_CARD_MODAL",
        modalProps: {
          userId: selectedMember?.value,
          onDone: (newCard) => {
            setSimplePayment(newCard);
            getPaymentMethodsFn();
          },
        },
      });
      return;
    }
    setSimplePayment(payment);
  };

  if (isSubmitting) {
    return (
      <ModalContainer>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              New Payment
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        dateFormat="Pp"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.date?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.date.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Member
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="member"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={members}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Amount
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.amount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.amount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    To Account
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="account"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={[
                          { label: "Standard", value: "" },
                          ...clubAccounts.map((account) => ({
                            value: account.id,
                            label: account.name,
                          })),
                        ]}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.account?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.account.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftTextareaAutosize
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        fontSize={14}
                        placeholder="Enter a description"
                        rowsMin={3}
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          minHeight: "100px",
                        }}
                        rowsMax={3}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.description.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Payment Method
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="paymentMethod"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={paymentMethodTypes}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          {clubPaysFees ? null : (
            <SoftBox mt={2}>
              <SoftBox display="flex" justifyContent="end" mt={3}>
                <SoftBox>
                  <SoftTypography
                    variant="body1"
                    fontWeight="light"
                    color="text"
                  >
                    Fees:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography variant="body1" fontWeight="medium">
                    <CurrencyFormat
                      value={(totalFees ?? 0) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          )}
          <SoftBox mt={2}>
            <SoftBox display="flex" justifyContent="end" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={(totalAmount ?? 0) / 100}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            {selectedPaymentMethodType?.value === paymentTypes.CARD_FWD && (
              <Stack direction="row" gap="20px">
                <PaymentOptions
                  userId={selectedMember?.value}
                  groupedPayments={filterQuickPaymentMethods(
                    paymentMethods,
                    amount * 100
                  )}
                  onSelected={({ payment }) => {
                    handleSelectedPayment(payment);
                  }}
                  handleCardReaload={getPaymentMethodsFn}
                  isLoading={isLoadingPaymentMethods || isLoadingPaymentMethods}
                  CustomButton={
                    <Stack direction="row" alignItems="center">
                      {simplePayment && (
                        <Stack
                          direction="row"
                          sx={{
                            borderRadius: "8px",
                            padding: "9px",
                            marginRight: "-14px",
                            paddingRight: "23px",
                            backgroundColor: "#F7F7F8",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          <img src={ConveyorBelt} />
                          <RenderPayment
                            payment={simplePayment}
                            enableAccountView
                          />
                        </Stack>
                      )}
                      {
                        <SoftButton
                          color="dark"
                          disabled={isLoadingPaymentMethods}
                          sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            gap: "4px",
                          }}
                        >
                          <AccountBalanceWalletOutlined
                            sx={{ marginBottom: "2px" }}
                          />
                          Select Payment
                        </SoftButton>
                      }
                    </Stack>
                  }
                />

                <SoftButton
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoadingPaymentMethods || !simplePayment}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "4px",
                  }}
                >
                  <CheckCircleOutline sx={{ marginBottom: "2px" }} /> Take
                  Payment
                </SoftButton>
              </Stack>
            )}
            {selectedPaymentMethodType?.value !== paymentTypes.CARD_FWD &&
              selectedPaymentMethodType?.value !== paymentTypes.ACH_FWD && (
                <SoftButton
                  disabled={
                    isSubmitting ||
                    totalAmount === 0 ||
                    !selectedPaymentMethodType
                  }
                  type="submit"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  {selectedPaymentMethodType?.value === "PAYMENT_REQUEST"
                    ? "Send Request"
                    : "Add Payment"}
                </SoftButton>
              )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddPaymentModal.propTypes = {
  handleClose: PropTypes.func,
  payment: PropTypes.object,
};
