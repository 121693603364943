import { removeUndefined } from "src/utils/utils";

export const apiUrls = {
  booking: {
    complete: (clubId: string, locationId: string, bookingId: string) =>
      `club/${clubId}/location/${locationId}/booking/${bookingId}/complete`,
  },
  payments: {
    calculateFees: (clubId: string, locationId: string) =>
      `club/${clubId}/location/${locationId}/fees`,
    createPayment: (userId: string, clubId: string) =>
      `user/${userId}/club/${clubId}/payment`,
    createPaymentIntent: (userId: string, clubId: string, locationId: string) =>
      `user/${userId}/club/${clubId}/location/${locationId}/fwd/createPaymentIntent`,
    chargeCreditCard: (userId: string, clubId: string, locationId: string) =>
      `user/${userId}/club/${clubId}/location/${locationId}/fwd/chargeCreditCard`,
    chargeAch: (userId: string, clubId: string, locationId: string) =>
      `user/${userId}/club/${clubId}/location/${locationId}/fwd/chargeAch`,
    sendPaymentRequest: (userId: string, clubId: string, locationId: string) =>
      `user/${userId}/club/${clubId}/location/${locationId}/payment-request`,
    resendPaymentRequest: (
      clubId: string,
      locationId: string,
      paymentId: string
    ) =>
      `club/${clubId}/location/${locationId}/payment-request/${paymentId}/send`,
    capturePaymentIntent: (
      userId: string,
      clubId: string,
      locationId: string,
      paymentIntentId: string
    ) =>
      `user/${userId}/club/${clubId}/location/${locationId}/fwd/capturePaymentIntent/${paymentIntentId}`,
    createStripePaymentIntent: (
      userId: string,
      clubId: string,
      locationId: string
    ) =>
      `user/${userId}/club/${clubId}/location/${locationId}/stripe/createPaymentIntent`,
    getPayments: (userId: string, clubId: string) =>
      `user/${userId}/payments${getAppendableParams({ clubId })}`,
  },
  flightCredits: {
    createFlightCredit: (clubId: string, locationId: string) =>
      `club/${clubId}/location/${locationId}/flight-credit`,
    deleteFlightCredit: (
      clubId: string,
      locationId: string,
      flightCreditId: string
    ) =>
      `club/${clubId}/location/${locationId}/flight-credit/${flightCreditId}`,
  },
  fuelReimbursement: {
    createFuelReimbursement: (clubId: string, locationId: string) =>
      `club/${clubId}/location/${locationId}/fuel-reimbursement`,
    deleteFuelReimbursement: (
      clubId: string,
      locationId: string,
      fuelReimbursementId: string
    ) =>
      `club/${clubId}/location/${locationId}/fuel-reimbursement/${fuelReimbursementId}`,
  },
  user: {
    paymentMethods: {
      get: (userId: string, clubId?: string, locationId?: string) =>
        `user/${userId}/payment-methods${getAppendableParams({
          clubId,
          locationId,
        })}`,
      add: (userId: string) => `user/${userId}/payment-methods`,
      submit: (clubId: string, locationId: string, invoiceId: string) =>
        `club/${clubId}/location/${locationId}/invoices/${invoiceId}/payment`,
    },
  },
  invoice: {
    create: (clubId: string, locationId: string) =>
      `club/${clubId}/location/${locationId}/invoices`,
    update: (clubId: string, locationId: string, invoiceId: string) =>
      `club/${clubId}/location/${locationId}/invoices/${invoiceId}`,
    refund: (clubId: string, locationId: string, invoiceId: string) =>
      `club/${clubId}/location/${locationId}/invoices/${invoiceId}/refund`,
  },
  accounts: {
    getBalances: (userId: string, clubId: string, locationId: string) =>
      `user/${userId}/club/${clubId}/location/${locationId}/accounts/balance`,
  },
  transactions: {
    get: (userId: string, clubId: string) =>
      `user/${userId}/club/${clubId}/transactions`,
  },
  quickbooks: {
    class: {
      get: (clubId: string) => `club/${clubId}/quickbooks/class`,
    },
    items: {
      get: (clubId: string) => `club/${clubId}/quickbooks/items`,
    },
  },
};

export const getAppendableParams = (params: any) => {
  const search = new URLSearchParams(removeUndefined(params)).toString();

  return search ? `?${search}` : "";
};
