import { useEffect, useState } from "react";
import Swal from "sweetalert2";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

// Images
import masterCardLogo from "src/assets/images/logos/mastercard.png";
import visaLogo from "src/assets/images/logos/visa.png";

import { doc, updateDoc } from "firebase/firestore";
import {
  getUsersCollection,
  getUserPaymentMethodsCollection,
} from "src/features/user/collections";
import { getFunctions, httpsCallable } from "firebase/functions";

import { openModal$ } from "src/modals/modalConfiguration";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

function PaymentMethods({ user }) {
  const { borderWidth, borderColor } = borders;
  const [defaultCard, setUserDefaultCard] = useState(user?.defaultCard);

  const { data: paymentMethods } = useRealtimeCollectionData(
    getUserPaymentMethodsCollection(user.uid),
    false
  );

  const deleteCard = (cardId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          console.log("delete card", cardId);
          if (cardId.toString().includes("pm_")) {
            const functions = getFunctions();
            const deleteCardFromCustomer = httpsCallable(
              functions,
              "deleteCardFromCustomer"
            );
            deleteCardFromCustomer({
              paymentMethodId: cardId,
            });
          } else {
            await updateDoc(
              doc(getUserPaymentMethodsCollection(user.uid), cardId),
              {
                deletedAt: new Date(),
                deleted: true,
              }
            );
          }
        }
      });
  };

  const setDefaultCard = async (cardId) => {
    await updateDoc(doc(getUsersCollection(), user?.uid), {
      defaultCard: cardId,
    });
    setUserDefaultCard(cardId);
  };

  // const handleClose = (result) => {
  //   if (result.success) fetchCards();
  // };

  useEffect(() => {
    if (user?.defaultCard) {
      setUserDefaultCard(user.defaultCard);
    }
  }, [user]);

  return (
    <Card id="delete-account">
      <SoftBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Payment Methods
        </SoftTypography>
        <SoftButton
          variant="gradient"
          color="dark"
          onClick={() =>
            openModal$.next({
              modalName: "ADD_CARD_MODAL",
              modalProps: { userId: user.uid },
            })
          }
        >
          <Icon
            sx={{
              fontWeight: "bold",
            }}
          >
            add
          </Icon>
          &nbsp;add new card
        </SoftButton>
      </SoftBox>
      <SoftBox p={2}>
        <Grid container spacing={3}>
          {paymentMethods.length > 0 &&
            paymentMethods
              .filter((paymentMethod) => paymentMethod.type === "CARD_FWD")
              .map((paymentMethod) => {
                return (
                  <Grid item xs={12} xl={12} key={paymentMethod.id}>
                    <SoftBox
                      border={`${borderWidth[1]} solid ${borderColor}`}
                      borderRadius="lg"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      p={2}
                    >
                      <SoftBox
                        component="img"
                        src={
                          paymentMethod?.cardDetails?.brand === "visa"
                            ? visaLogo
                            : masterCardLogo
                        }
                        alt="master card"
                        width="10%"
                        mr={2}
                      />
                      <SoftBox display="flex" flexDirection="column" mr={2}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                          {paymentMethod?.cardDetails?.lastFourDigits}
                        </SoftTypography>
                        <SoftTypography variant="caption" fontWeight="medium">
                          {paymentMethod?.name} - Exp:{" "}
                          {paymentMethod?.cardDetails?.expMonth}/
                          {paymentMethod?.cardDetails?.expYear}
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="row" mr={2}>
                        {paymentMethod.id !== defaultCard && (
                          <SoftBox ml={1} lineHeight={0}>
                            <Tooltip
                              title="Delete Card"
                              placement="top"
                              onClick={() => deleteCard(paymentMethod.id)}
                            >
                              <Icon
                                sx={{
                                  cursor: "pointer",
                                }}
                                fontSize="small"
                              >
                                delete
                              </Icon>
                            </Tooltip>
                          </SoftBox>
                        )}
                        <SoftBox lineHeight={0} ml={1}>
                          {paymentMethod.id === defaultCard ? (
                            <Tooltip title="Default Card" placement="top">
                              <Icon fontSize="small" color="info">
                                star
                              </Icon>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Set As Default"
                              placement="top"
                              onClick={() => setDefaultCard(paymentMethod.id)}
                            >
                              <Icon
                                sx={{
                                  cursor: "pointer",
                                }}
                                fontSize="small"
                              >
                                star_outline
                              </Icon>
                            </Tooltip>
                          )}
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                );
              })}
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default PaymentMethods;
