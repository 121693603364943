import { useState, useEffect } from "react";
import toastr from "toastr";

import { Grid, Divider } from "@mui/material";

import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { createFuelReimbursement } from "src/services/fuelReimbursement.api";

import { ThreeDots } from "react-loader-spinner";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftTextareaAutosize from "src/components/SoftTextareaAutosize";
import { useUser } from "src/features/user/UserProvider";
import SoftDatePicker from "src/components/SoftDatePicker";

const schema = yup
  .object({
    date: yup.date().required("A transaction date is required."),
    member: yup.object().required("A member must be selected."),
    aircraft: yup.object().required("An aircraft must be selected."),
    total: yup
      .number()
      .moreThan(0, "Total must be greater than 0.")
      .required("A total must be provided."),
    rate: yup
      .number()
      .moreThan(0, "Rate must be greater than 0.")
      .required("A rate must be provided."),
    quantity: yup
      .number()
      .moreThan(0, "Quantity must be greater than 0.")
      .required("A quantity must be provided."),
  })
  .required();

export function AddFuelReimbursementModal({ handleClose }) {
  const { selectedClubId, selectedLocationId, clubUsers, locationAircraft } =
    useClubs();
  const { user, userId } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [members, setMembers] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      account: { label: "Standard", value: "" },
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const credits = watch("credits");

  useEffect(() => {
    const memberOptions = Array.from(clubUsers?.values?.()).map((member) => ({
      value: member.uid,
      label: member.displayName,
    }));

    setMembers(memberOptions);
  }, [clubUsers]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const addCreditsResult = await createFuelReimbursement({
      rate: data.rate * 100,
      date: data.date,
      aircraft: data.aircraft,
      quantity: data.quantity,
      total: data.total * 100,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      description: data.description,
      createdBy: {
        name: user.displayName,
        id: userId,
      },
      createdAt: new Date(),
      userId: data.member.value,
    }).catch((error) => {
      console.error("Error adding fuel reimbursement", error);
      toastr.error("Error adding fuel reimbursement");
      setIsSubmitting(false);
    });

    if (addCreditsResult) {
      toastr.success(`Reimbursement added successfully.`);
      handleClose();
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <ModalContainer>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Add Fuel Reimbursement
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="date"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <SoftDatePicker
                        dateFormat="Pp"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors?.date?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.date.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Member
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="member"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={members}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.member?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.member.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Aircraft
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="aircraft"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={locationAircraft.map((aircraft) => ({
                          value: aircraft.id,
                          label: `${aircraft.tailNumber} - ${aircraft.displayName}`,
                        }))}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                </SoftBox>
                {errors?.aircraft?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.aircraft.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Rate
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="rate"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.rate?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.rate.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Quantity
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="quantity"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        style={{
                          ".MuiInputAdornment-root": { right: "-54px" },
                        }}
                        currencySymbol="gals"
                        placeholder="0.0"
                        decimalPlaces={1}
                        outputFormat="number"
                        adornmentOnRight
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.quantity?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.quantity.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Total
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="total"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.total?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.total.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftTextareaAutosize
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        fontSize={14}
                        placeholder="Enter a description"
                        rowsMin={3}
                        sx={{
                          width: "100%",
                          fontSize: "14px",
                          minHeight: "100px",
                        }}
                        rowsMax={3}
                      />
                    )}
                  />
                  {errors?.description?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.description.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton
              disabled={isSubmitting || credits === 0}
              type="submit"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Add Reimbursement
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddFuelReimbursementModal.propTypes = {
  handleClose: PropTypes.func,
};
