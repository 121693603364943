import { useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubMembershipsCollection } from "src/features/club/collections";
import { deleteField } from "firebase/firestore";

const schema = yup
  .object({
    label: yup.string().required("A name for the membership is required."),
    hasCost: yup.boolean(),
    cost: yup.number().when("hasCost", {
      is: true,
      then: (elementSchema) => elementSchema.required("A cost is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    billed: yup.object().when("hasCost", {
      is: true,
      then: (elementSchema) =>
        elementSchema.required(
          "Please select how often the membership is billed."
        ),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    hasFlightCredits: yup.boolean(),
    flightCredits: yup.number().when("hasFlightCredits", {
      is: true,
      then: (elementSchema) =>
        elementSchema.required("Number of flight credits is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    paid: yup.object().when("hasFlightCredits", {
      is: true,
      then: (elementSchema) =>
        elementSchema.required(
          "Please select how often the credits are paid out."
        ),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
    taxable: yup.object(),
    active: yup.boolean(),
  })
  .required();

const billedOptions = [
  { label: "Annually", value: "annually" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Monthly", value: "monthly" },
  { label: "Weekly", value: "weekly" },
];

export function AddMembershipModal({ handleClose, membership }) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { addData, updateData } = entityCrudUtils();

  const [hasCost, setHasCost] = useState(membership ? !!membership.cost : true);
  const handleSetHasCost = () => setHasCost(!hasCost);

  const [hasFlightCredits, setHasFlightCredits] = useState(
    membership ? !!membership.flightCredits : true
  );
  const handleSetHasFlightCredits = () =>
    setHasFlightCredits(!hasFlightCredits);

  let defaultValues;
  if (membership) {
    defaultValues = {
      taxable: membership.cost
        ? {
            label: membership.cost.taxable ? "Yes" : "No",
            value: membership.cost.taxable,
          }
        : undefined,
      cost: membership.cost ? membership.cost.amount : undefined,
      billed: membership.cost
        ? billedOptions.find(
            (option) => option.value === membership.cost.billed
          )
        : undefined,
      flightCredits: membership.flightCredits
        ? membership.flightCredits.amount
        : undefined,
      paid: membership.flightCredits
        ? billedOptions.find(
            (option) => option.value === membership.flightCredits.paid
          )
        : undefined,
      label: membership.label,
      discountFlight: membership.discountFlight,
      discountInstructor: membership.discountInstructor,
    };
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: defaultValues || {
      taxable: { label: "Yes", value: true },
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    if (data.taxable) data.taxable = data.taxable.value;
    if (data.billed) data.billed = data.billed.value;
    if (data.paid) data.paid = data.paid.value;

    const newMembership = {
      label: data.label,
      active: true,
    };
    if (hasCost) {
      newMembership.cost = {
        amount: data.cost,
        billed: data.billed,
      };
    } else if (membership?.cost) {
      newMembership.cost = deleteField();
    }
    if (hasFlightCredits) {
      newMembership.flightCredits = {
        amount: data.flightCredits,
        paid: data.paid,
      };
    } else if (membership?.flightCredits) {
      newMembership.flightCredits = deleteField();
    }
    if (data.discountFlight && data.discountFlight > 0) {
      newMembership.discountFlight = data.discountFlight;
    } else if (membership?.discountFlight) {
      newMembership.discountFlight = deleteField();
    }
    if (data.discountInstructor && data.discountInstructor > 0) {
      newMembership.discountInstructor = data.discountInstructor;
    } else if (membership?.discountInstructor) {
      newMembership.discountInstructor = deleteField();
    }

    if (membership)
      await updateData(
        {
          entity: getClubMembershipsCollection(
            selectedClubId,
            selectedLocationId
          ),
          pathSegmentsArr: [membership.id],
        },
        newMembership
      );
    else
      await addData(
        getClubMembershipsCollection(selectedClubId, selectedLocationId),
        newMembership
      );
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {membership ? "Edit Membership" : "Add Membership"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Membership Name
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Standard Membership"
                  {...register("label", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={4}>
                <SoftBox ml={0.5} pt={5} display="inline-block">
                  <Checkbox
                    checked={hasCost}
                    onClick={handleSetHasCost}
                    {...register("hasCost", {
                      required: false,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetHasCost}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Has Cost?
                  </SoftTypography>
                </SoftBox>
              </Grid>
              {hasCost && (
                <>
                  <Grid item xs={12} sm={2}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Cost
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="cost"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftCurrencyInput
                          currencySymbol="$"
                          placeholder="0.00"
                          outputFormat="number"
                          onBlur={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.cost?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.cost.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Billed
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="billed"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={billedOptions}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.billed?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.billed.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={1} mt={0}>
              <Grid item xs={4}>
                <SoftBox ml={0.5} pt={5} display="inline-block">
                  <Checkbox
                    checked={hasFlightCredits}
                    onClick={handleSetHasFlightCredits}
                    {...register("hasFlightCredits", {
                      required: false,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetHasFlightCredits}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Award Flight Credits?
                  </SoftTypography>
                </SoftBox>
              </Grid>
              {hasFlightCredits && (
                <>
                  <Grid item xs={12} sm={2}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Flight Credits
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="flightCredits"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftCurrencyInput
                          style={{
                            ".MuiInputAdornment-root": { right: "-54px" },
                          }}
                          currencySymbol="hrs"
                          placeholder="0.0"
                          decimalPlaces={1}
                          outputFormat="number"
                          adornmentOnRight
                          onBlur={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.flightCredits?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.flightCredits.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Paid
                      </SoftTypography>
                    </SoftBox>
                    <Controller
                      control={control}
                      name="paid"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={billedOptions}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.paid?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.paid.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Flight Discount
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="discountFlight"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftCurrencyInput
                      style={{
                        ".MuiInputAdornment-root": { right: "-54px" },
                      }}
                      currencySymbol="%"
                      placeholder="0"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      outputFormat="number"
                      adornmentOnRight
                      onBlur={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.discountFlight?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.discountFlight.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Instructor Discount
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="discountInstructor"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftCurrencyInput
                      style={{
                        ".MuiInputAdornment-root": { right: "-54px" },
                      }}
                      currencySymbol="%"
                      placeholder="0"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      outputFormat="number"
                      adornmentOnRight
                      onBlur={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.discountInstructor?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.discountInstructor.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {membership
                      ? "Save Membership Plan"
                      : "Add Membership Plan"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddMembershipModal.propTypes = {
  handleClose: PropTypes.func,
  membership: PropTypes.object,
};
