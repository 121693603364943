import { useEffect, useState } from "react";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
// import { useUser } from "src/features/user/UserProvider";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc, getDoc } from "firebase/firestore";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// OrderDetails page components
import Header from "./components/Header";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import { toTitleCase } from "src/features/utils";
import {
  getClubFlightCredits,
  getClubInvoices,
} from "src/features/club/collections";
import CreditDetails from "./components/CreditDetails/CreditDetails";
import FlightCreditSummary from "./components/FlightCreditSummary/FlightCreditSummary";

function FlightCreditDetails() {
  const { selectedClubId } = useClubs();
  const { flightCreditId } = useParams();
  const navigate = useNavigate();

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const flightCreditDocRef = doc(
    getClubFlightCredits(selectedClubId),
    flightCreditId
  );
  const { data: flightCredit, isDataLoaded: loadedFlightCredit } =
    useRealtimeDocumentData(flightCreditDocRef);

  const [totalApplied, setTotalApplied] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  const getFlightCreditData = async () => {
    if (flightCredit?.lineItems && flightCredit?.lineItems.length > 0) {
      setTotalApplied(
        flightCredit?.lineItems.reduce((acc, item) => acc + item.amount, 0)
      );

      const formattedLineItems = [];
      for await (const item of flightCredit.lineItems) {
        if (item.invoiceId && item.invoiceId !== "") {
          const invoiceDocRef = doc(
            getClubInvoices(selectedClubId),
            item.invoiceId
          );
          const invoiceData = await getDoc(invoiceDocRef);
          const invoice = invoiceData.data();

          const invoiceLineItem = invoice?.lineItems.find(
            (lineItem) => lineItem.id === item.lineItemId
          );

          if (invoiceLineItem) {
            formattedLineItems.push({
              id: invoiceLineItem.id,
              invoiceId: invoice.id,
              transactionNumber: invoice.transactionNumber,
              description: invoiceLineItem.description,
              qty: invoiceLineItem.quantity,
              rate: invoiceLineItem.rate,
              discount: invoiceLineItem.discount,
              tax: invoiceLineItem.tax,
              total: invoiceLineItem.total,
              amountPaid: item.amount,
              type: "INVOICE",
            });
          }
        }
      }
      setLineItems(formattedLineItems);
    }
  };

  const navigateToInvoice = (item) => {
    console.log("Navigate to invoice", item);
    navigate({
      pathname: `/billing/invoice/${item.invoiceId}`,
      search: createSearchParams({
        isNewInvoice: "true",
      }).toString(),
    });
  };

  useEffect(() => {
    console.log("Line Items", lineItems);
  }, [lineItems]);

  useEffect(() => {
    getFlightCreditData();
  }, [flightCredit, loadedFlightCredit]);

  if (!flightCredit?.createdAt && loadedFlightCredit && flightCredit?.id) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>
                  Can not find this flight credit...
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (!loadedFlightCredit || !flightCredit) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={9}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>Loading...</SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }
  return (
    <DashboardLayout>
      <>
        <DashboardNavbar
          pageTitle={`Flight Credit #${flightCredit.transactionNumber}`}
        />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <Header payment={flightCredit} />
                </SoftBox>
                <SoftBox my={3} px={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <CreditDetails flightCredit={flightCredit} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      sx={{
                        ml: "auto",
                      }}
                    >
                      <FlightCreditSummary flightCredit={flightCredit} />
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </Grid>
            {lineItems?.length > 0 && (
              <>
                <Grid item xs={12} lg={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <SoftBox pt={3} px={2}>
                      <SoftTypography variant="h6" fontWeight="medium">
                        Flight Credit Applications
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox pb={3} px={2}>
                      <SoftBox mb={3}>
                        <SoftBox p={3}>
                          <SoftBox width="100%" overflow="auto">
                            <Table
                              sx={{
                                minWidth: "32rem",
                              }}
                            >
                              <SoftBox component="thead">
                                <TableRow>
                                  <SoftBox
                                    component="th"
                                    width={"150px"}
                                    py={1.5}
                                    px={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="h6"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      Item
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="h6"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      Description
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="th"
                                    py={1.5}
                                    pl={3}
                                    pr={1}
                                    textAlign="left"
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="h6"
                                      color="text"
                                      fontWeight="medium"
                                    >
                                      Qty
                                    </SoftTypography>
                                  </SoftBox>
                                </TableRow>
                              </SoftBox>
                              <TableBody>
                                {lineItems?.map((item) => {
                                  return (
                                    <TableRow
                                      key={item.invoiceLineItem}
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          backgroundColor: "#f0f0f0",
                                        },
                                      }}
                                      onClick={() => navigateToInvoice(item)}
                                    >
                                      <SoftBox
                                        component="td"
                                        textAlign="left"
                                        p={1}
                                        borderBottom={borderBottom}
                                      >
                                        <SoftTypography
                                          variant="body2"
                                          color="text"
                                        >
                                          {toTitleCase(item.type)} #:{" "}
                                          {item?.transactionNumber}
                                        </SoftTypography>
                                      </SoftBox>
                                      <SoftBox
                                        component="td"
                                        textAlign="left"
                                        p={1}
                                        borderBottom={borderBottom}
                                      >
                                        <SoftTypography
                                          variant="body2"
                                          color="text"
                                        >
                                          {item?.description}
                                        </SoftTypography>
                                      </SoftBox>
                                      <SoftBox
                                        component="td"
                                        textAlign="left"
                                        py={1}
                                        pr={1}
                                        pl={3}
                                        borderBottom={borderBottom}
                                      >
                                        <SoftTypography
                                          variant="body2"
                                          color="text"
                                        >
                                          {(
                                            item.qty ||
                                            item.quantity ||
                                            0
                                          ).toFixed(2)}
                                        </SoftTypography>
                                      </SoftBox>
                                    </TableRow>
                                  );
                                })}
                                <TableRow key={"Total"}>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    />
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="right"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      <b>Sub Total:</b>
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {totalApplied.toFixed(2)}
                                    </SoftTypography>
                                  </SoftBox>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </SoftBox>
        <Footer />
      </>
    </DashboardLayout>
  );
}

export default FlightCreditDetails;
