import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

import { formatCurrency, toTitleCase } from "src/features/utils";
import { format } from "date-fns";

function CreditDetails({ flightCredit }) {
  const { borderWidth, borderColor } = borders;

  const renderPayments = () => (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Flight Credit Details
      </SoftTypography>
      {flightCredit && (
        <SoftBox
          border={`${borderWidth[1]} solid ${borderColor}`}
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={flightCredit?.id}
          p={2}
          mt={2}
        >
          <SoftBox display="flex" flexDirection="column" alignItems="start">
            <SoftTypography
              display="block"
              style={{ marginBottom: "5px" }}
              variant="caption"
              fontWeight="medium"
            >
              Given By: {flightCredit?.createdBy?.name}
            </SoftTypography>
            <SoftTypography
              display="block"
              style={{ marginBottom: "5px" }}
              variant="caption"
              fontWeight="medium"
            >
              Date Given:{" "}
              {flightCredit?.createdAt?.toDate
                ? format(
                    flightCredit?.createdAt.toDate(),
                    "yyyy/MM/dd hh:mm:ss"
                  )
                : "---"}
            </SoftTypography>
            <SoftTypography
              display="block"
              style={{ marginBottom: "5px" }}
              variant="caption"
              fontWeight="medium"
            >
              Description: {flightCredit?.description || <i>None Given</i>}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
    </>
  );

  return renderPayments();
}

CreditDetails.defaultProps = {
  flightCredit: PropTypes.object.isRequired,
};

export default CreditDetails;
