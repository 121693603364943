import { useState, useEffect } from "react";
import SoftBox from "src/components/SoftBox";

import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "src/components/TabPanel";

import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";
import * as yup from "yup";
import CurrencyFormat from "react-currency-format";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";

import { ThreeDots } from "react-loader-spinner";

import Cube from "src/components/Icons/Cube";
import Document from "src/components/Icons/Document";
import {
  capturePaymentIntent,
  createPaymentIntent,
} from "src/services/payments.api";
import { calculateFees } from "src/services/payments.api";

const stylePayment = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  maxWidth: "100vw",
  p: 4,
};

const schema = yup
  .object({
    amount: yup
      .number()
      .positive("Amount must be a positive number.")
      .required("An amount is required."),
  })
  .required();

function AddFundsContent({ amount, fees, paymentIntent, handleClose }) {
  const { selectedClubId, selectedLocationId, selectedLocation } = useClubs();
  const { userId } = useUser();
  const [paymentError, setPaymentError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setPaymentDetails] = useState(null);
  const [paymentToken, setPaymentToken] = useState(null);

  const setupForm = async () => {
    const paymentForm = await window.Forward.createPaymentElement({
      apiKey: import.meta.env.VITE_FWD_API_KEY,
      clientSecret: paymentIntent.client_secret,
    });
    paymentForm.mount("#payment-form", {
      onChange: (event) => {
        if (event.ready) {
          setPaymentDetails({
            type: event.paymentMethodType,
            card: { ...event.card, ...event.billingDetails.address },
          });
        }
      },
      onReady: (tokenize) => {
        tokenize();
      },
      onSuccess: async ({ token }) => {
        setPaymentToken(token);
      },
      onError: (error) => {
        console.error(error);
        setPaymentError(error);
      },
    });
  };

  useEffect(() => {
    setupForm();
  }, [paymentIntent]);

  const completePayment = async () => {
    setIsSubmitting(true);
    const paymentResult = await capturePaymentIntent({
      userId,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      paymentMethodId: paymentToken,
      paymentIntentId: paymentIntent.id,
    }).catch((error) => {
      console.error("error", error);
      setIsSubmitting(false);
      // setPaymentError(error);
    });
    setIsSubmitting(false);
    if (paymentResult) {
      handleClose();
    }
  };

  const clubPaysCCFees = selectedLocation?.preferences?.clubPaysFees || false;
  const clubPaysACHFees =
    selectedLocation?.preferences?.clubPaysAchFees || false;

  return (
    <Card sx={stylePayment}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <SoftTypography variant="h3" p={2} alignSelf="flex-start">
            Checkout/Payment
          </SoftTypography>
        </Grid>
        <Grid item md={4} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mt={2} mb={2}>
              <SoftTypography mb={1} variant="h5">
                Your Order
              </SoftTypography>
              <hr />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="medium">
                Cost Summary
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Amount:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body2" fontWeight="medium">
                  <CurrencyFormat
                    value={amount || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            {((paymentIntent?.payment_method_types?.some(
              (val) => val === "bank"
            ) &&
              !clubPaysACHFees) ||
              (!paymentIntent?.payment_method_types?.some(
                (val) => val === "bank"
              ) &&
                !clubPaysCCFees)) && (
              <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                <SoftBox>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Payment Fees:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography variant="body2" fontWeight="medium">
                    <CurrencyFormat
                      value={(fees.pilotFee || 0) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={(fees.totalCharge || 0) / 100}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item md={8} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" p={2}>
            <SoftBox my={2}>
              <div id="payment-form"></div>
              {paymentError && (
                <SoftTypography marginTop={1} fontSize={14} color="error">
                  {paymentError}
                </SoftTypography>
              )}
              <SoftBox display="flex" flexDirection="row-reverse" mt={4}>
                <SoftButton
                  onClick={completePayment}
                  disabled={isSubmitting}
                  color="primary"
                >
                  Proceed with payment
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export function AddFundsModalFwd({ handleClose }) {
  const { selectedClubId, selectedLocation, selectedLocationId } = useClubs();
  const { userId } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingFees, setIsLoadingFees] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const clubPaysCCFees = selectedLocation?.preferences?.clubPaysFees || false;
  const clubPaysACHFees =
    selectedLocation?.preferences?.clubPaysAchFees || false;
  const [fees, setFees] = useState(null);

  const {
    watch,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { amount } = watch();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const paymentIntent = await createPaymentIntent({
      amount: fees.totalCharge,
      currency: "USD",
      referenceId: "123",
      userId: userId,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      description: "Add Funds",
      fees,
      paymentMethodTypes: tabValue === 0 ? ["bank"] : ["card"],
    }).catch((error) => {
      console.error("error", error);
      setIsSubmitting(false);
    });

    setPaymentIntent(paymentIntent);
    setIsSubmitting(false);
  };

  const updateFees = async (amount) => {
    setIsLoadingFees(true);
    const feesResponse = await calculateFees({
      paymentMethod: tabValue === 0 ? "ACH_FWD" : "CARD_FWD",
      amount,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      dissmissPreFlightFee: true,
    }).catch((error) => {
      console.error("error", error);
      setIsSubmitting(false);
    });

    setFees(feesResponse);
    setIsLoadingFees(false);
  };

  useEffect(() => {
    const fundingAmount = parseInt(amount * 100, 10);
    if (amount) {
      updateFees(fundingAmount);
    }
  }, [amount, tabValue]);

  if (isSubmitting) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (paymentIntent) {
    return (
      <AddFundsContent
        amount={amount}
        fees={fees}
        paymentIntent={paymentIntent}
        handleClose={handleClose}
      />
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Add Funds
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Amount
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  <Controller
                    control={control}
                    name="amount"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.amount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.amount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox mt={2} display="flex">
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  backgroundColor: "transparent",
                }}
              >
                <Tab label="ACH" icon={<Cube />} />
                <Tab label="Credit Card" icon={<Document />} />
              </Tabs>
            </AppBar>
          </SoftBox>
          <SoftBox mt={2}>
            <TabPanel value={tabValue} index={0}>
              {!clubPaysACHFees && (
                <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                  <SoftBox>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Payment Fees:
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={1}>
                    <SoftTypography variant="body2" fontWeight="medium">
                      <CurrencyFormat
                        value={(fees?.pilotFee ?? 0) / 100}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator
                        prefix="$"
                      />
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              )}
              <SoftBox display="flex" justifyContent="space-between" mt={3}>
                <SoftBox>
                  <SoftTypography
                    variant="body1"
                    fontWeight="light"
                    color="text"
                  >
                    Total:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography variant="body1" fontWeight="medium">
                    <CurrencyFormat
                      value={(fees?.totalCharge ?? 0) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" justifyContent="flex-end" mt={3}>
                <SoftTypography variant="caption">
                  <i>
                    <b>Note:</b> ACH payments may take up to 4 working days
                  </i>
                </SoftTypography>
              </SoftBox>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {!clubPaysCCFees && (
                <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                  <SoftBox>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Payment Fees:
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={1}>
                    <SoftTypography variant="body2" fontWeight="medium">
                      <CurrencyFormat
                        value={(fees?.pilotFee || 0) / 100}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator
                        prefix="$"
                      />
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              )}
              <SoftBox display="flex" justifyContent="space-between" mt={3}>
                <SoftBox>
                  <SoftTypography
                    variant="body1"
                    fontWeight="light"
                    color="text"
                  >
                    Total:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography variant="body1" fontWeight="medium">
                    <CurrencyFormat
                      value={(fees?.totalCharge || 0) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </TabPanel>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton
              disabled={isSubmitting || isLoadingFees || !fees}
              type="submit"
              color="primary"
            >
              Next
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddFundsContent.propTypes = {
  handleClose: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
  paymentIntent: PropTypes.object,
};

AddFundsModalFwd.propTypes = {
  handleClose: PropTypes.func,
};
