import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import {
  IInvoice,
  IPayment,
  LineItem,
  PaymentStatus,
  paymentTypes,
  RefundLineItem,
} from "src/interfaces";
import { removeUndefined } from "src/utils/utils";
import { Currency } from "src/types";

export const calculateFees = async ({
  paymentMethod,
  amount,
  clubId,
  locationId,
  dissmissPreFlightFee = false,
}: {
  paymentMethod?: string;
  amount: number;
  clubId: string;
  locationId: string;
  dissmissPreFlightFee: boolean;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.calculateFees(clubId, locationId),
      params: removeUndefined({
        paymentMethod,
        amount: amount,
        dissmissPreFlightFee,
      }),
    })
  ).call();
  return result;
};

export const getPaymentMethods = async ({
  userId,
  clubId,
  locationId,
}: {
  userId: string;
  clubId: string;
  locationId: string;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.get<any>({
      url: apiUrls.user.paymentMethods.get(userId, clubId, locationId),
    })
  ).call();

  return result;
};

export const chargeCreditCard = async ({
  paymentMethodId,
  clubId,
  amount,
  currency = Currency.USD,
  locationId,
  userId,
  description,
  date,
  dissmissPreFlightFee = false,
}: {
  paymentMethodId: string;
  clubId: string;
  amount: number;
  currency: Currency;
  locationId: string;
  userId: string;
  description: string;
  date: Date;
  dissmissPreFlightFee: boolean;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.chargeCreditCard(userId, clubId, locationId),
      params: removeUndefined({
        paymentMethodId,
        clubId,
        amount,
        currency,
        locationId,
        userId,
        description,
        date,
        dissmissPreFlightFee,
      }),
    })
  ).call();
  return result;
};

export const chargeAch = async ({
  paymentMethodId,
  clubId,
  amount,
  currency = Currency.USD,
  locationId,
  userId,
  description,
  date,
  dissmissPreFlightFee = false,
}: {
  paymentMethodId: string;
  clubId: string;
  amount: number;
  currency: Currency;
  locationId: string;
  userId: string;
  description: string;
  date: Date;
  dissmissPreFlightFee: boolean;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.chargeAch(userId, clubId, locationId),
      params: removeUndefined({
        paymentMethodId,
        clubId,
        amount,
        currency,
        locationId,
        userId,
        description,
        date,
        dissmissPreFlightFee,
      }),
    })
  ).call();
  return result;
};

export const sendPaymentRequest = async ({
  acceptedPaymentMethods,
  clubId,
  amount,
  currency = Currency.USD,
  locationId,
  userId,
  description,
  date,
  dissmissPreFlightFee = false,
  id,
}: {
  acceptedPaymentMethods: ("ach" | "cc")[];
  clubId: string;
  amount: number;
  currency: Currency;
  locationId: string;
  userId: string;
  description: string;
  date: Date;
  dissmissPreFlightFee: boolean;
  id?: string;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.sendPaymentRequest(userId, clubId, locationId),
      params: removeUndefined({
        acceptedPaymentMethods,
        clubId,
        amount,
        currency,
        locationId,
        userId,
        description,
        date,
        dissmissPreFlightFee,
        id,
      }),
    })
  ).call();
  return result;
};

export const resendPaymentRequest = async ({
  clubId,
  locationId,
  id,
}: {
  clubId: string;
  locationId: string;
  id: string;
}): Promise<IPayment[]> => {
  console.log("resendPaymentRequest", clubId, locationId, id);
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.resendPaymentRequest(clubId, locationId, id),
      params: {},
    })
  ).call();
  return result;
};

export const createPayment = async ({
  userId,
  clubId,
  accountId,
  locationId,
  description,
  date,
  type,
  paymentCurrency = Currency.USD,
  status,
  amount,
  totalAmount,
  unappliedAmount,
  fees,
  clubPaysFees,
}: {
  userId: string;
  clubId: string;
  accountId?: string;
  locationId: string;
  description?: string;
  date: Date;
  type: paymentTypes;
  paymentCurrency: Currency;
  status: PaymentStatus;
  amount: number;
  totalAmount: number;
  unappliedAmount: number;
  fees: any;
  clubPaysFees: boolean;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.createPayment(userId, clubId),
      params: removeUndefined({
        accountId,
        locationId,
        description,
        date,
        type,
        paymentCurrency,
        status,
        amount,
        totalAmount,
        unappliedAmount,
        fees,
        clubPaysFees,
      }),
    })
  ).call();
  return result;
};

export const createPaymentIntent = async ({
  amount,
  currency,
  referenceId,
  userId,
  clubId,
  fees,
  locationId,
  description,
  paymentMethodTypes = ["card"],
  capture,
}: {
  amount: number;
  currency: string;
  referenceId: string;
  userId: string;
  clubId: string;
  fees: any;
  locationId: string;
  description?: string;
  paymentMethodTypes: ["card" | "bank"];
  capture?: boolean;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.createPaymentIntent(userId, clubId, locationId),
      params: removeUndefined({
        amount,
        currency,
        referenceId,
        paymentMethodTypes,
        userId,
        clubId,
        fees,
        locationId,
        description,
        capture,
      }),
    })
  ).call();
  return result;
};

export const capturePaymentIntent = async ({
  userId,
  clubId,
  locationId,
  paymentMethodId,
  paymentIntentId,
  existingPaymentId,
}: {
  userId: string;
  clubId: string;
  locationId: string;
  paymentIntentId: string;
  paymentMethodId: string;
  existingPaymentId?: string;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.capturePaymentIntent(
        userId,
        clubId,
        locationId,
        paymentIntentId
      ),
      params: removeUndefined({
        paymentMethodId,
        existingPaymentId,
      }),
    })
  ).call();
  return result;
};

export const createStripePaymentIntent = async ({
  receiptEmail,
  locationId,
  description,
  paymentType,
  amount,
  currency,
  metadata,
  userId,
  clubId,
}: {
  receiptEmail: string;
  locationId: string;
  description?: string;
  paymentType?: string;
  amount: number;
  currency: string;
  metadata: any;
  userId: string;
  clubId: string;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.payments.createStripePaymentIntent(
        userId,
        clubId,
        locationId
      ),
      params: removeUndefined({
        receiptEmail,
        locationId,
        description,
        paymentType,
        amount,
        currency,
        metadata,
      }),
    })
  ).call();
  return result;
};

export const addPaymentMethod = async ({
  userId,
  type,
  token,
  name,
  cardDetails,
  account,
}: {
  userId: string;
  type: string;
  token: string;
  name: string;
  cardDetails?: any;
  account?: any;
}): Promise<{ success: boolean }> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.user.paymentMethods.add(userId),
      params: removeUndefined({
        type,
        token,
        cardDetails,
        name,
        account,
        createdAt: new Date(),
      }),
    })
  ).call();
  return result;
};

export const submitPayment = async ({
  invoiceId,
  clubId,
  locationId,
  lineItems,
}: {
  invoiceId: string;
  clubId: string;
  locationId: string;
  lineItems: Array<Partial<LineItem>>;
}): Promise<IInvoice> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.user.paymentMethods.submit(clubId, locationId, invoiceId),
      params: removeUndefined({ payments: lineItems }),
    })
  ).call();
  return result;
};

export const refundInvoice = async ({
  invoiceId,
  locationId,
  clubId,
  refundItems,
  reason,
}: {
  invoiceId: string;
  locationId: string;
  clubId: string;
  refundItems: Array<Partial<RefundLineItem>>;
  reason: string;
}): Promise<IPayment[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.invoice.refund(clubId, locationId, invoiceId),
      params: removeUndefined({ refundItems, reason }),
    })
  ).call();
  return result;
};
