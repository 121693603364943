import DefaultCell from "../../table-components/DefaultCell";
import HoursCell from "./components/HoursCell";
// import SoftBox from 'src/components/SoftBox';
// import IconButton from '@mui/material/IconButton';
// import Icon from '@mui/material/Icon';

// const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
//   px: 0.9,

//   '& .material-icons, .material-icons-round': {
//     fontSize: `${size.md} !important`,
//   },

//   '& .MuiTypography-root': {
//     display: 'none',

//     [breakpoints.up('sm')]: {
//       display: 'inline-block',
//       lineHeight: 1.2,
//       ml: 0.5,
//     },
//   },
// });

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
  TRACK: "TRACK",
};

export const getTableColumns = () => [
  {
    Header: "Name",
    accessor: "displayName",
    Cell: ({ value }: any) => <DefaultCell value={value} />,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }: any) => <DefaultCell value={value} ignoreCapitalize />,
  },
  {
    Header: "balance",
    accessor: "accountBalance",
    width: "50px",
    Cell: ({ value }: any) => <HoursCell value={value} />,
  },
  // {
  //   Header: 'action',
  //   accessor: 'action',
  //   width: '14%',
  //   align: 'center',
  //   Cell: ({ row: { original } }) => (
  //     <SoftBox display="flex" flexDirection="column" alignItems="center">
  //       <SoftBox display="flex" flexDirection="row">
  //         <IconButton
  //           aria-label="edit"
  //           sx={iconButtonStyles}
  //           onClick={(e) => {
  //             e.stopPropagation();
  //             onAction(TableActions.EDIT, original);
  //           }}
  //         >
  //           <Icon>edit</Icon>
  //         </IconButton>
  //       </SoftBox>
  //     </SoftBox>
  //   ),
  // },
];
