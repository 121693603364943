import SoftInput from "src/components/SoftInput";
import { formatCurrency } from "src/features/utils";
import { discountType, LineItem } from "src/interfaces";
import debounce from "lodash/debounce";
import { useCallback, useState } from "react";
import { QbkItem } from "./QbkItem";
import {
  IQuickbookClass,
  IQuickbookItem,
} from "src/interfaces/quickbook.interface";
import { QbkClass } from "./QbkClass";

interface NewLineItemProps {
  onLineItemAdded: (lineItem: Partial<LineItem>) => void;
  hasQuickbooks: boolean;
  quickbooksItems?: IQuickbookItem[];
  quickbooksClasses?: IQuickbookClass[];
}

const initialState = {
  description: "",
  quantity: 0,
  rate: 0,
  discount: { amount: 0, type: discountType.PERCENTAGE },
};

export const NewLineItem = ({
  hasQuickbooks,
  quickbooksItems,
  quickbooksClasses,
  onLineItemAdded,
}: NewLineItemProps) => {
  const onLineItemAddedDebounced = useCallback(
    debounce((props) => {
      onLineItemAdded(props), setNewItemState(initialState);
    }, 800),
    []
  );
  const [newItemState, setNewItemState] = useState<any>(initialState);

  const updateState = (key: string, value: string | number) => {
    setNewItemState((prev: any) => {
      const newValue = { ...prev, [key]: value };
      onLineItemAddedDebounced(newValue);
      return newValue;
    });
  };

  return (
    <>
      <td style={{ backgroundColor: "white" }} />
      <td />
      {hasQuickbooks && quickbooksItems && quickbooksItems?.length > 0 && (
        <td>
          <QbkItem
            quickbooksItems={quickbooksItems!}
            onLineItemEditted={onLineItemAddedDebounced}
          />
        </td>
      )}

      <td>
        <SoftInput
          placeholder="Description"
          value={newItemState.description}
          onChange={(e) => updateState("description", e.target.value)}
        />
      </td>
      {hasQuickbooks && quickbooksClasses && quickbooksClasses?.length > 0 && (
        <td>
          <QbkClass
            quickbooksClasses={quickbooksClasses!}
            onLineItemEditted={onLineItemAddedDebounced}
          />
        </td>
      )}
      <td>
        <SoftInput
          placeholder="Quantity"
          type="number"
          value={newItemState.quantity}
          onChange={(e) => updateState("quantity", +e.target.value)}
        />
      </td>
      <td>
        <SoftInput
          placeholder="Rate"
          type="number"
          value={newItemState.rate}
          onChange={(e) => updateState("rate", +e.target.value)}
        />
      </td>
      <td>
        <SoftInput
          placeholder="Discount"
          type="number"
          value={newItemState.discount}
          onChange={(e) => updateState("discount.amount", +e.target.value)}
        />
      </td>
      <td>{formatCurrency(0, true)} </td>
      <td />
      <td />
    </>
  );
};
