// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useEffect, useState } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";

function AccountBalanceCard({
  bgColor = "white",
  title = {
    fontWeight: "medium",
    text: "",
  },
  percentage = {
    color: "success",
    text: "",
  },
  icon,
  direction = "right",
}) {
  const { clubAccounts, accountBalances } = useClubs();
  const [standardBalance, setStandardBalance] = useState("");
  const [accountDetails, setAccountDetails] = useState(null);

  useEffect(() => {
    const accounts = [];
    if (accountBalances) {
      for (const [accountId, balance] of accountBalances) {
        if (accountId === "STANDARD") {
          setStandardBalance(formatCurrency(balance / 100));
          continue;
        }
        if (balance === 0) continue;
        const accountDetails = clubAccounts.find(
          (account) => account.id === accountId
        );

        if (accountId === "fuelReimbursements") {
          accounts.push({
            id: accountId,
            balance: balance / 100,
            balanceFormatted: formatCurrency(balance / 100),
            label: `Reimbursements`,
          });
        } else if (accountId === "flightCredits") {
          accounts.push({
            id: accountId,
            balance: balance,
            balanceFormatted: `${balance} hrs`,
            label: `Flight Credits`,
          });
        } else if (accountDetails) {
          accounts.push({
            id: accountId,
            balance: balance / 100,
            balanceFormatted: formatCurrency(balance / 100),
            label: `${accountDetails?.name}`,
          });
        }
      }
      setAccountDetails(
        accounts.sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [accountBalances]);

  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
            <Grid item xs={8}>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                    <SoftTypography
                      variant="button"
                      color={bgColor === "white" ? "text" : "white"}
                      opacity={bgColor === "white" ? 1 : 0.7}
                      textTransform="capitalize"
                      fontWeight={title.fontWeight}
                    >
                      {title.text}
                    </SoftTypography>
                    <SoftTypography
                      variant="h5"
                      fontWeight="bold"
                      color={bgColor === "white" ? "dark" : "white"}
                    >
                      {standardBalance}{" "}
                      <SoftTypography
                        variant="button"
                        color={percentage.color}
                        fontWeight="bold"
                      >
                        {percentage.text}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                {accountDetails &&
                  accountDetails.map((account) => (
                    <Grid item xs={12} key={account.id} mt={1}>
                      <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                        <SoftTypography
                          variant="button"
                          color={bgColor === "white" ? "text" : "white"}
                          opacity={bgColor === "white" ? 1 : 0.7}
                          textTransform="capitalize"
                          fontWeight={title.fontWeight}
                        >
                          {account.label}
                        </SoftTypography>
                        <SoftTypography
                          variant="h5"
                          fontWeight="bold"
                          color={bgColor === "white" ? "dark" : "white"}
                        >
                          {account.balanceFormatted}{" "}
                          <SoftTypography
                            variant="button"
                            color={percentage.color}
                            fontWeight="bold"
                          >
                            {percentage.text}
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            {direction === "right" ? (
              <Grid item xs={4}>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AccountBalanceCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default AccountBalanceCard;
