import { useState } from "react";
import SoftBox from "src/components/SoftBox";

import { Grid, Divider } from "@mui/material";

import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
// import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";
import * as yup from "yup";
// import { useForm, Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object({
    amount: yup
      .number()
      .positive("Amount must be a positive number.")
      .required("An amount is required."),
  })
  .required();

function AddMissingStripeDetailsModal({ stripeAccount, handleClose }) {
  // const { selectedClubId } = useClubs();
  // const { userId } = useUser();
  // const [stripeError, setStripeError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    // control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    console.log("stripe data", data);
    setIsSubmitting(false);
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftBox
            display="flex"
            justifyContent="center"
            position="relative"
            alignItems="center"
          >
            <SoftTypography variant="h5" fontWeight="bold">
              Account Verification
            </SoftTypography>
          </SoftBox>
          <Divider />
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Nothing to verify.
                  </SoftTypography>
                </SoftBox>
                <SoftBox flexGrow={1} ml={2}>
                  {/* <Controller
                    control={control}
                    name="amount"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftCurrencyInput
                        currencySymbol="$"
                        placeholder="0.00"
                        outputFormat="number"
                        onBlur={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                   */}
                  {errors?.amount?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.amount.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox
            mt={3}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <SoftBox />
            <SoftButton
              disabled={isSubmitting}
              type="submit"
              color="primary"
              onClick={handleClose}
            >
              Submit
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddMissingStripeDetailsModal.propTypes = {
  handleClose: PropTypes.func,
  stripeAccount: PropTypes.object,
};

export default AddMissingStripeDetailsModal;
