import UserSettings from "src/pages/user/account/settings";
import Billing from "src/pages/user/account/billing";
import AccountDetails from "src/pages/club-billing/accounts-new/AccountDetailsPage";
import ManageClub from "src/pages/backoffice/ManageClubs/ManageClub";
import ManageUser from "src/pages/backoffice/ManageUsers/ManageUser";
import ClubSetupWizard from "src/pages/club-setup";
import AddClub from "src/pages/backoffice/ManageClubs/AddClub";
import Terms from "src/pages/terms";
import TransactionDetails from "src/pages/club-billing/invoices/transaction-details";
import PaymentRequestDetails from "src/pages/club-billing/request-details";
import ClubAnnouncements from "src/pages/club-announcements";
import NewClubAnnouncement from "src/pages/club-announcements/new-announcement";
import CourseDetails from "src/pages/lms/courses/course-details";
import CourseOverview from "src/pages/lms/classroom/course-overview";
import LessonOverview from "src/pages/lms/classroom/lesson-overview";
import LessonDetails from "src/pages/lms/courses/lesson-details";
import InvoiceDetailWrapper from "src/pages/club-billing/invoices/invoice-details/InvoiceDetailWrapper";
import InvoiceEdit from "src/pages/club-billing/invoices/invoice-edit/InvoiceEdit";
import PaymentRequest from "src/pages/club-billing/invoices/invoice-payment";
import InvoicePayment from "src/pages/club-billing/invoices/invoice-payment/payment";
import InvoicePaymentConfirmation from "src/pages/club-billing/invoices/invoice-payment/confirmation";

import AcceptInvite from "src/pages/auth/AcceptInvite";
import PaymentDetailWrapper from "src/pages/club-billing/payments/payment-details/PaymentDetailWrapper";
import FlightCreditDetailWrapper from "src/pages/club-billing/flight-credits/credit-details/FlightCreditDetailWrapper";

const mainRoutes = [
  {
    name: "Edit User Profile",
    key: "edit-user-profile",
    route: "/profile/edit",
    component: <UserSettings />,
  },
  {
    name: "Billing",
    key: "billing",
    route: "/account/billing",
    component: <Billing />,
  },
  {
    name: "Member Account",
    key: "member-account",
    route: "/billing/accounts/:userId",
    component: <AccountDetails />,
  },
  {
    name: "Transaction",
    key: "invoices",
    route: "/billing/invoices/:id",
    component: <TransactionDetails />,
  },
  {
    name: "Invoice Details",
    key: "invoice",
    route: "/billing/invoice/:invoiceId",
    component: <InvoiceDetailWrapper />,
  },
  {
    name: "Invoice Edit",
    key: "invoiceEdit",
    route: "/billing/invoice/edit/:invoiceId",
    component: <InvoiceEdit />,
  },
  {
    name: "Invoice Edit",
    key: "invoiceEdit",
    route: "/billing/invoice/add",
    component: <InvoiceEdit />,
  },
  {
    name: "Payment Details",
    key: "payments",
    route: "/billing/payments/:paymentId",
    component: <PaymentDetailWrapper />,
  },
  {
    name: "Flight Credit Details",
    key: "flight-credits",
    route: "/billing/flight-credits/:flightCreditId",
    component: <FlightCreditDetailWrapper />,
  },
  {
    name: "Payment Request",
    key: "paymentRequest",
    route: "/billing/paymentRequest/:id",
    component: <PaymentRequestDetails />,
  },
  // {
  //   name: 'Aircraft Report Details',
  //   key: 'summary',
  //   route: '/reports/aircraft/summary/:id',
  //   component: <AircraftReportDetails />,
  // },
  {
    name: "Club/School Details",
    key: "manage-club",
    route: "/manage-clubs/club-details/:clubId",
    component: <ManageClub />,
  },
  {
    name: "Announcements",
    key: "club-announcements",
    route: "/club-announcements",
    component: <ClubAnnouncements />,
  },
  {
    name: "New Announcement",
    key: "new-announcement",
    route: "/club-announcements/new-announcement",
    component: <NewClubAnnouncement />,
  },
  {
    name: "Course Details",
    key: "courses",
    route: "/lms/courses/:courseId",
    component: <CourseDetails />,
  },
  {
    name: "Lesson Details",
    key: "courses",
    route: "/lms/courses/:courseId/:stageId/:lessonId",
    component: <LessonDetails />,
  },
  {
    name: "Course Overview",
    key: "students",
    route: "/lms/students/:studentId/courses/:enrollmentId/:enrolledCourseId",
    component: <CourseOverview />,
  },
  {
    name: "Lesson",
    key: "students",
    route:
      "/lms/students/:studentId/lessons/:enrollmentId/:enrolledCourseId/:lessonId",
    component: <LessonOverview />,
  },
  {
    name: "Course Overview",
    key: "classroom",
    route:
      "/training/classroom/:studentId/courses/:enrollmentId/:enrolledCourseId",
    component: <CourseOverview />,
  },
  {
    name: "Lesson",
    key: "classroom",
    route:
      "/training/classroom/:studentId/lessons/:enrollmentId/:enrolledCourseId/:lessonId",
    component: <LessonOverview />,
  },
  {
    name: "Add New Club",
    key: "add-new-club",
    route: "/manage-clubs/add-new-club",
    component: <AddClub />,
  },
  {
    name: "Setup Wizard",
    key: "club-setup",
    route: "/club-setup",
    component: <ClubSetupWizard />,
  },
  {
    name: "User Details",
    key: "user-details",
    route: "/manage-users/user-details/:userId",
    component: <ManageUser />,
  },
  {
    name: "Accept Invite",
    key: "accept-invite",
    route: "/auth/accept-invite/:inviteId",
    component: <AcceptInvite authenticated />,
  },
  {
    name: "Payment Request",
    key: "pay",
    route: "/pay/:paymentRequestId",
    component: <PaymentRequest />,
  },
  {
    name: "Payment Request",
    key: "pay",
    route: "/pay/:clubId/:paymentRequestId",
    component: <PaymentRequest />,
  },
  {
    name: "Pay Invoice",
    key: "payment",
    route: "/pay/:paymentRequestId/payment",
    component: <InvoicePayment />,
  },
  {
    name: "Pay Invoice",
    key: "payment",
    route: "/pay/:clubId/:paymentRequestId/payment",
    component: <InvoicePayment />,
  },
  {
    name: "Payment Confirmation",
    key: "confirmation",
    route: "/pay/:clubId/:clubBillingEntryId/confirmation",
    component: <InvoicePaymentConfirmation />,
  },
  {
    name: "Terms of Service",
    key: "terms",
    route: "/terms",
    component: <Terms />,
  },
];

export default mainRoutes;
