import { IInvoice, InvoiceStatus, LineItem } from "src/interfaces";

export const isInvoicePaid = (invoice?: IInvoice | null) => {
  if (!invoice) return false;
  const amountPaid =
    invoice?.payments?.reduce((acc, payment) => {
      if (payment?.status === "succeeded")
        return acc + (payment?.amount ?? 0) / 100;
      else return acc;
    }, 0) ?? 0;
  const paid =
    amountPaid >= (invoice?.totalCost ?? invoice.totalAmount) ||
    invoice.status === InvoiceStatus.PAID;
  return paid;
};

export const getLineItemTotal = (lineItem: LineItem, clubPaysFees: boolean) => {
  let currentValue = lineItem.quantity * lineItem.rate;
  const tax = lineItem.tax ?? 0;
  const fees =
    lineItem.payment && !lineItem.payment.clubPaysFees
      ? (lineItem.fees ?? 0) + (lineItem.payment?.fees ?? 0)
      : !lineItem.payment && !clubPaysFees
      ? lineItem.fees ?? 0
      : 0;

  let discount = 0;
  if (lineItem?.discount && lineItem.discount.amount > 0) {
    if (lineItem.discount.type === "PERCENTAGE") {
      discount = (lineItem.discount.amount / 100) * currentValue;
      currentValue = currentValue - discount;
    } else {
      currentValue = currentValue - lineItem.discount.amount;
    }
  }

  console.log("GET LINE ITEM TAX", tax);
  const currentPrice = currentValue + tax + fees;
  return currentPrice;
};

export const calculateInvoiceSummary = (
  invoice?: IInvoice | null,
  clubPaysFees: boolean = false
) => {
  if (!invoice?.lineItems?.length)
    return { total: 0, tax: 0, discount: 0, fees: 0, refunded: 0 };
  return invoice?.lineItems.reduce(
    (acc, val) => {
      // const currentValue = val.quantity * val.rate;
      const currentPrice = getLineItemTotal(val, clubPaysFees);
      const tax = val.tax ?? 0;
      const fees = (val.fees ?? 0) + (val.payment?.fees ?? 0);
      const refunded = val.refunded ?? 0;

      acc.tax += tax;
      if (val.payment && !val.payment.clubPaysFees) acc.fees += fees;
      else if (!val.payment && !clubPaysFees) acc.fees += fees;
      acc.refunded += refunded;

      let discount = 0;
      if (val?.discount && val.discount.amount > 0) {
        if (val.discount.type === "PERCENTAGE") {
          const lineItemTotal = val.quantity * val.rate;
          discount = (val.discount.amount / 100) * lineItemTotal;
        } else {
          discount = val.discount.amount;
        }
      }
      acc.discount += discount;
      acc.total += currentPrice;

      console.log("Club Pays Fees?", clubPaysFees);
      console.log("Fees", acc.fees);

      return acc;
    },
    { total: 0, tax: 0, discount: 0, fees: 0, refunded: 0 }
  );
};

import { Discount, discountType } from "src/interfaces";

export const calculateDiscountAmount = (
  amountInCents: number,
  discount: Discount
) => {
  return discount.type === discountType.PERCENTAGE
    ? (amountInCents * discount.amount) / 100
    : discount.amount;
};

export const applyDiscountToAmount = (
  amountInCents: number,
  discount: Discount
) => {
  const discountTotalInCents = calculateDiscountAmount(amountInCents, discount);

  return Math.max(amountInCents - discountTotalInCents, 0);
};
