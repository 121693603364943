import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { ITransaction } from "src/interfaces";
import { removeUndefined } from "src/utils/utils";
import { parse } from "date-fns";

export const getAccountBalances = async ({
  userId,
  clubId,
  locationId,
}: {
  userId: string;
  clubId: string;
  locationId: string;
}): Promise<{ [accountId: string]: number }> => {
  const result = await (
    await ApiWrapper.get<any>({
      url: apiUrls.accounts.getBalances(userId, clubId, locationId),
    })
  ).call();

  console.log("FETCH BALANCES result", result, userId, clubId, locationId);

  return result;
};
