import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import ModalContainer from "src/components/ModalContainer";
// import SoftBadge from "src/components/SoftBadge";
import ReservationDate from "src/components/ReservationDate";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import BookingData from "src/modals/CheckOutModal/components/BookingData";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import {
  getClubBookingAuditTrailCollection,
  getClubBookingsCollection,
} from "src/features/club/collections";
import { useClubs } from "src/features/club/ClubProvider";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
} from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import SoftTypography from "src/components/SoftTypography";
import {
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import Grid from "@mui/material/Grid";
import { BookingInfoDynamicProps } from "src/modals/BookingInfoModal/bookingInfoDynamicProps";
import { MaintenanceDynamicProps } from "src/modals/BookingInfoModal/maintenanceDynamicProps";
import SoftButton from "src/components/SoftButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import styled from "@emotion/styled";
import { useUser } from "src/features/user/UserProvider";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { AuditTrailCard } from "src/components/AuditTrailCard";
import { CheckOutModal } from "src/modals/CheckOutModal";
import { CheckInModal } from "src/modals/CheckInModal";
import { CancelBookingModal } from "src/modals/CancelBookingModal";
import { UserInfoModal } from "src/modals/UserInfoModal";
import { BookingModal } from "src/modals/BookingModal";
import { GradeLessonModal } from "src/modals/GradeLessonModal";
import { pdf } from "@react-pdf/renderer";
import { BookingDispatchPdf } from "src/pdfs/BookingDispatchPdf";
import {
  getAircraftCollection,
  getAircraftRemindersCollection,
  getAircraftSquawksCollection,
} from "src/features/aircraft/collections";
import { getUserTaskListsCollection } from "src/features/user/collections";
import { getUsersNotificationsCollection } from "src/features/user-notifications/collections";
import { MeetingRoomDynamicProps } from "./meetingRoomDynamicProps";
import BookingLessonData from "./components/BookingLessonData";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { usePermissions } from "src/hooks/usePermissions";
import { hasAircraftSquawks } from "src/features/utils/aircraft.utils";
import Notes from "src/components/Notes";
import { useNavigate } from "react-router-dom";
import { openModal$ } from "../modalConfiguration";
import TaskTable from "./components/TaskTable";
import { useLms } from "src/features/lms/LmsProvider";
import { GradeTasksModal } from "../GradeTasksModal";

const CustomizedAccordion = styled(Accordion)(() => ({
  "& .MuiPaper-root": {
    color: "darkslategray",
    borderRadius: "20px !important",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  "&:before": {
    backgroundColor: "transparent",
  },
  "& .MuiAccordionSummary-root": {
    color: "darkslategray",
    borderRadius: "20px !important",
    boxShadow: "none",
    border: "none",
  },
  padding: "0",
  boxShadow: "none",
}));

function DynamicPropCard({ dynamicProp, bookingData, aircraftHasSquawks }) {
  return dynamicProp ? (
    <SoftBox>
      <SoftBox display="flex" alignItems="center">
        <SoftBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          borderRadius="lg"
          width="2rem"
          height="2rem"
          mr={2}
        >
          <dynamicProp.icon
            sx={{
              display: "grid",
              placeItems: "center",
            }}
            color="dark"
            fontSize="medium"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography
            variant="button"
            color="dark"
            fontWeight="medium"
            gutterBottom
          >
            {dynamicProp.label}
          </SoftTypography>
          <Stack
            direction="row"
            position="relative"
            sx={{ wordBreak: "break-word" }}
          >
            {aircraftHasSquawks &&
              !!dynamicProp?.aircraftSquawkIcon &&
              dynamicProp.aircraftSquawkIcon}
            <SoftTypography variant="caption" color="text">
              {dynamicProp.accessor(bookingData)}
            </SoftTypography>
          </Stack>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  ) : null;
}

function BookingInfoModal({ bookingId, handleClose, resources, pilots }) {
  const {
    selectedClubId,
    selectedLocationId,
    selectedClub,
    selectedLocation,
    clubUsers,
    instructors: clubInstructors,
  } = useClubs();
  const { userId: loggedUserId } = useUser();
  const { getSingleEnrolledCourse, getTasksFromEnrolledCourse } = useLms();
  const { hasAccess } = usePermissions();
  const navigate = useNavigate();
  const { canEditBooking, canViewBookingDetails } = useUserPermissions();
  const { updateDataFromRef } = entityCrudUtils();
  const [selectedAircraftId, setSelectedAircraftId] = useState(null);

  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserInfoOpen = (userId) => {
    setSelectedUserId(userId);
    setOpenUserInfo(true);
  };

  const handleUserInfoClose = () => {
    setSelectedUserId(null);
    setOpenUserInfo(false);
  };

  const [openCheckOut, setOpenCheckOut] = useState(false);
  const handleCheckOutOpen = () => setOpenCheckOut(true);
  const handleCheckOutClose = (closeParent) => {
    setOpenCheckOut(false);
    if (closeParent) {
      handleClose();
    }
  };

  const [openCheckIn, setOpenCheckIn] = useState(false);
  const handleCheckInOpen = () => setOpenCheckIn(true);
  const handleCheckInClose = (closeParent) => {
    setOpenCheckIn(false);
    if (closeParent) {
      handleClose();
    }
  };

  const [openGradeInstruction, setOpenGradeInstruction] = useState(false);
  const handleGradeInstructionOpen = () => setOpenGradeInstruction(true);
  const handleGradeInstructionClose = (newTasks = []) => {
    newTasks.length && setTasks(newTasks);
    setOpenGradeInstruction(false);
  };

  const [openCancel, setOpenCancel] = useState(false);
  const handleCancelOpen = () => setOpenCancel(true);
  const handleCancelClose = (closeParent) => {
    setOpenCancel(false);
    if (closeParent) {
      handleClose();
    }
  };

  const handleViewInvoice = () => {
    if (bookingData.invoiceIds?.length > 0) {
      navigate(
        `/billing/invoice/${bookingData.invoiceIds[0]}?isNewInvoice=true`
      );
    } else {
      navigate(`/billing/invoices/${bookingData.transactionId}`);
    }
  };

  const handlePrintDispatch = async () => {
    const doc = (
      <BookingDispatchPdf
        bookingData={bookingData}
        clubName={selectedClub.name}
        aircraftData={aircraftData}
        locationData={selectedLocation}
        pilots={pilots}
        aircraftSquawks={aircraftSquawks}
        aircraftReminders={aircraftReminders}
      />
    );

    const asPdf = pdf(doc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setEditModalOpen(true);
  const handleEditModalClose = (closeParent) => {
    setEditModalOpen(false);
    if (closeParent) {
      handleClose();
    }
  };

  const handleUndoCheckOut = async () => {
    await updateDataFromRef(
      doc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingId
      ),
      {
        dispatched: false,
      }
    );
  };

  const aircraftDataRef =
    selectedAircraftId &&
    doc(getAircraftCollection(selectedClubId), selectedAircraftId);
  const { data: aircraftData } = useRealtimeDocumentData(aircraftDataRef);

  const bookingDataRef = doc(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    bookingId
  );
  const { data: bookingData } = useRealtimeDocumentData(bookingDataRef);

  const bookingAuditTrailRef = getClubBookingAuditTrailCollection(
    selectedClubId,
    selectedLocationId,
    bookingId
  );

  const { data: auditTrail } = useRealtimeCollectionData(
    bookingAuditTrailRef,
    true
  );

  const squawksRef = getAircraftSquawksCollection(
    selectedClubId,
    selectedAircraftId
  );
  const { data: aircraftSquawks } = useRealtimeCollectionData(squawksRef, true);

  const remindersRef = getAircraftRemindersCollection(
    selectedClubId,
    selectedAircraftId
  );
  const { data: aircraftReminders } = useRealtimeCollectionData(
    remindersRef,
    false
  );

  const [tasks, setTasks] = useState([]);
  const [taskListId, setTaskListId] = useState("");

  const getTasks = async () => {
    const taskListDocs = await getDocs(
      query(
        getUserTaskListsCollection(bookingData.extendedProps.pilot.value),
        where("bookingId", "==", bookingId),
        where("deleted", "==", false)
      )
    );
    if (taskListDocs.docs?.length) {
      const taskList = {
        ...taskListDocs.docs[0].data(),
        id: taskListDocs.docs[0].id,
      };
      const enrollmentIds = {};
      const gradesByTaskId = {};
      taskList.taskGrades?.forEach((grade) => {
        enrollmentIds[grade.enrolledCourseId] = grade.enrollmentId;
        gradesByTaskId[grade.taskId] = grade;
      });
      const taskListTasks = [];
      await Promise.all(
        Object.keys(enrollmentIds).map(async (courseId) => {
          const enrolledCourseData = await getSingleEnrolledCourse(
            bookingData.extendedProps.pilot.value,
            enrollmentIds[courseId],
            courseId
          );
          taskListTasks.push(
            ...getTasksFromEnrolledCourse(enrolledCourseData, (task) => {
              return (
                gradesByTaskId[task.id]?.enrollmentId ===
                  enrollmentIds[courseId] &&
                gradesByTaskId[task.id]?.enrolledCourseId === courseId
              );
            })
          );
        })
      );
      setTaskListId(taskList.id);
      setTasks(taskListTasks);
    }
  };

  useEffect(() => {
    if (
      bookingData?.extendedProps?.pilot?.value &&
      bookingData.extendedProps?.instructor?.value &&
      !bookingData?.extendedProps?.lesson
    ) {
      getTasks();
    }
  }, [bookingData]);

  const handleAcceptBooking = async () => {
    const notifications = await getDocs(
      query(
        getUsersNotificationsCollection(
          bookingData?.extendedProps?.instructor?.value
        ),
        where("bookingId", "==", bookingId)
      )
    );
    notifications.forEach(async (notification) => {
      await updateDataFromRef(notification.ref, {
        notificationType: "booking",
        title: "Booking Accepted",
        read: true,
      });
    });

    await updateDataFromRef(
      doc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingId
      ),
      {
        confirmed: true,
      }
    );
  };

  const handleRejectBooking = async () => {
    const notifications = await getDocs(
      query(
        getUsersNotificationsCollection(
          bookingData?.extendedProps?.instructor?.value
        ),
        where("bookingId", "==", bookingId)
      )
    );
    notifications.forEach(async (notification) => {
      await deleteDoc(notification.ref);
    });
    await deleteDoc(
      doc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingId
      )
    );

    handleClose();
  };

  // const getBookingUser = async (userId) => {
  //   const bookingUser = await getUserWithId(userId);
  //   bookingData.createdBy = bookingUser.data();
  // };

  useEffect(() => {
    if (bookingData?.extendedProps?.aircraft?.value) {
      setSelectedAircraftId(bookingData.extendedProps.aircraft.value);
    }
    // if (bookingData?.createdBy && typeof bookingData.createdBy === "string") {
    //   getBookingUser(bookingData.createdBy);
    // }
  }, [bookingData]);

  const shouldShowTransferButton = useMemo(() => {
    return bookingData?.extendedProps?.instructor?.value === loggedUserId;
  }, [bookingData, loggedUserId]);

  const shouldShowAcceptTransfer = useMemo(
    () => (bookingData?.transferRequestInstructor ?? []).includes(loggedUserId),
    [bookingData, loggedUserId]
  );

  const handleBookingTransfer = async () => {
    const previousInstructor = bookingData.extendedProps.instructor.value;
    const newInstructor = clubInstructors.find((i) => i.id === loggedUserId);
    const resourceIds = bookingData.resourceIds
      .filter((id) => id !== previousInstructor)
      .concat([loggedUserId]);
    const instructor = {
      value: loggedUserId,
      label: newInstructor?.displayName,
    };
    await updateDoc(
      doc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData.id
      ),

      {
        ...bookingData,
        extendedProps: { ...bookingData.extendedProps, instructor },
        resourceIds,
        transferRequestInstructor: [],
      }
    );
    handleClose();
  };

  const handleCancelBookingTransfer = async () => {
    const transferRequestInstructor = (
      bookingData?.transferRequestInstructor ?? []
    ).filter((i) => i !== loggedUserId);

    await updateDoc(
      doc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData.id
      ),

      {
        ...bookingData,
        transferRequestInstructor,
      }
    );
    handleClose();
  };

  if (!bookingData) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  // const handleNewComment = async (comment) => {
  //   const existingComments = bookingData.comments || [];
  //   const newComment = {
  //     comment: comment.text,
  //     date: new Date(),
  //     image: user.photoURL ?? null,
  //     name: user.displayName,
  //     userId: loggedUserId,
  //     isInternal: comment.isInternal,
  //   };

  //   await updateDataFromRef(bookingDataRef, {
  //     comments: [...existingComments, newComment],
  //   });
  // };

  // const generalComments = bookingData.comments?.filter(
  //   (comment) => !comment.isInternal
  // );
  // const internalComments = bookingData.comments?.filter(
  //   (comment) => comment.isInternal
  // );

  if (openCheckOut) {
    return (
      <CheckOutModal
        handleClose={handleCheckOutClose}
        selectedBooking={{ ...bookingData, snapshot: { ref: bookingDataRef } }}
      />
    );
  }

  if (openCheckIn) {
    return (
      <CheckInModal
        handleClose={handleCheckInClose}
        selectedBooking={{ ...bookingData, snapshot: { ref: bookingDataRef } }}
      />
    );
  }

  if (openGradeInstruction) {
    if (taskListId) {
      return (
        <GradeTasksModal
          handleClose={handleGradeInstructionClose}
          studentId={bookingData?.extendedProps?.pilot?.value}
          tasks={tasks}
          lesson={bookingData?.extendedProps?.lesson}
        />
      );
    } else {
      return (
        <GradeLessonModal
          handleClose={handleGradeInstructionClose}
          booking={{ ...bookingData, snapshot: { ref: bookingDataRef } }}
        />
      );
    }
  }

  if (openCancel) {
    return (
      <CancelBookingModal
        handleClose={handleCancelClose}
        bookingRef={bookingDataRef}
        booking={{ ...bookingData, id: bookingId }}
      />
    );
  }

  if (editModalOpen) {
    return (
      <BookingModal
        handleClose={handleEditModalClose}
        oldBookingData={bookingData}
        isEditReservation
        resources={resources}
        pilots={pilots}
      />
    );
  }

  if (
    bookingData.extendedProps?.type?.value === "unavailable" ||
    bookingData.extendedProps?.type?.value === "requestOnly"
  ) {
    return (
      <BookingModal
        handleClose={handleClose}
        oldBookingData={bookingData}
        isEditReservation
        resources={resources}
        pilots={pilots}
      />
    );
  }
  console.log("debug:bookingData", bookingData);
  if (!bookingData || !bookingData.start || !bookingData.end) {
    return null;
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <ReservationDate
        start={bookingData.start.toDate()}
        end={bookingData.end.toDate()}
        type={bookingData.extendedProps?.type?.value}
      />
      <Divider />
      <Grid container mt={1} spacing={2} mb={1}>
        {(!bookingData.completed ||
          bookingData.extendedProps.type.value === "maintenance") &&
        canViewBookingDetails(bookingData) ? (
          <Grid item xs={12} sm={9}>
            <SoftBox mt={1} sx={{ height: "100%" }} borderRadius="lg" p={2}>
              <Grid container spacing={0} mt={1}>
                {bookingData.extendedProps?.type?.value === "meetingRoom" &&
                  MeetingRoomDynamicProps.map((dynamicProp) => (
                    <Grid key={dynamicProp.label} item xs={12} sm={4} mt={3}>
                      <DynamicPropCard
                        dynamicProp={dynamicProp}
                        bookingData={bookingData}
                        aircraftHasSquawks={hasAircraftSquawks(aircraftSquawks)}
                      />
                    </Grid>
                  ))}
                <BookingData
                  booking={bookingData}
                  mb={3}
                  userSelected={
                    bookingData?.extendedProps?.type?.value === "introFlight"
                      ? null
                      : handleUserInfoOpen
                  }
                />
                {bookingData.extendedProps?.lesson?.courseId &&
                  (loggedUserId ===
                    bookingData.extendedProps.instructor?.value ||
                    loggedUserId === bookingData.extendedProps.pilot?.value ||
                    hasAccess([
                      systemPermissions.VIEW_OTHERS_BOOKING_DETAILS,
                    ])) && (
                    <BookingLessonData
                      bookingLesson={bookingData.extendedProps.lesson}
                    />
                  )}
                {bookingData.extendedProps?.type?.value === "maintenance"
                  ? MaintenanceDynamicProps.map((dynamicProp) => (
                      <Grid key={dynamicProp.label} item xs={12} sm={4} mt={3}>
                        <DynamicPropCard
                          dynamicProp={dynamicProp}
                          bookingData={bookingData}
                          aircraftHasSquawks={hasAircraftSquawks(
                            aircraftSquawks
                          )}
                        />
                      </Grid>
                    ))
                  : BookingInfoDynamicProps.map((dynamicProp) => (
                      <Grid key={dynamicProp.label} item xs={12} sm={4} mt={3}>
                        <DynamicPropCard
                          dynamicProp={dynamicProp}
                          bookingData={bookingData}
                          aircraftHasSquawks={hasAircraftSquawks(
                            aircraftSquawks
                          )}
                        />
                      </Grid>
                    ))}
                {bookingData.notes?.length > 0 && (
                  <Grid item xs={12} mt={3}>
                    <SoftBox>
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox
                          display="grid"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="lg"
                          width="2rem"
                          height="2rem"
                          mr={2}
                        >
                          <TextSnippetIcon
                            sx={{
                              display: "grid",
                              placeItems: "center",
                            }}
                            color="dark"
                            fontSize="medium"
                          />
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="column">
                          <SoftTypography
                            variant="button"
                            color="dark"
                            fontWeight="medium"
                            gutterBottom
                          >
                            Notes
                          </SoftTypography>
                          <SoftTypography variant="caption" color="text">
                            {bookingData.notes}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                )}
                {bookingData.extendedProps?.pilot?.value &&
                bookingData.extendedProps?.instructor?.value &&
                !bookingData?.extendedProps?.lesson ? (
                  <Grid item xs={12} mt={3}>
                    <TaskTable tasks={tasks} taskListId={taskListId} />
                  </Grid>
                ) : null}
                <Grid item xs={12} mt={3}>
                  <Notes
                    fbRef={doc(
                      getClubBookingsCollection(
                        selectedClubId,
                        selectedLocationId
                      ),
                      bookingId
                    )}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={
              (bookingData.transactionId &&
                (hasAccess(systemPermissions.VIEW_INVOICES) ||
                  bookingData?.extendedProps?.pilot?.value === loggedUserId ||
                  bookingData?.extendedProps?.pilot2?.value === loggedUserId ||
                  bookingData?.extendedProps?.instructor?.value ===
                    loggedUserId)) ||
              hasAccess(systemPermissions.PREFLIGHT_ADMIN)
                ? 9
                : 12
            }
          >
            <SoftBox mt={1} sx={{ height: "100%" }} borderRadius="lg" p={2}>
              <Grid container spacing={0} mt={1}>
                <BookingData
                  booking={bookingData}
                  mb={3}
                  userSelected={
                    bookingData?.extendedProps?.type?.value === "introFlight"
                      ? null
                      : handleUserInfoOpen
                  }
                />
                {bookingData.extendedProps?.lesson?.courseId &&
                  (loggedUserId ===
                    bookingData.extendedProps.instructor?.value ||
                    loggedUserId === bookingData.extendedProps.pilot?.value ||
                    hasAccess([
                      systemPermissions.VIEW_OTHERS_BOOKING_DETAILS,
                    ])) && (
                    <BookingLessonData
                      bookingLesson={bookingData.extendedProps.lesson}
                    />
                  )}
                {bookingData.extendedProps?.type?.value === "maintenance"
                  ? MaintenanceDynamicProps.map((dynamicProp) => (
                      <Grid key={dynamicProp.label} item xs={12} sm={4} mt={3}>
                        <DynamicPropCard
                          dynamicProp={dynamicProp}
                          bookingData={bookingData}
                          aircraftHasSquawks={hasAircraftSquawks(
                            aircraftSquawks
                          )}
                        />
                      </Grid>
                    ))
                  : BookingInfoDynamicProps.map((dynamicProp) => (
                      <Grid key={dynamicProp.label} item xs={12} sm={4} mt={3}>
                        <DynamicPropCard
                          dynamicProp={dynamicProp}
                          bookingData={bookingData}
                          aircraftHasSquawks={hasAircraftSquawks(
                            aircraftSquawks
                          )}
                        />
                      </Grid>
                    ))}
                {bookingData.notes?.length > 0 && (
                  <Grid item xs={12} mt={3}>
                    <SoftBox>
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox
                          display="grid"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="lg"
                          width="2rem"
                          height="2rem"
                          mr={2}
                        >
                          <TextSnippetIcon
                            sx={{
                              display: "grid",
                              placeItems: "center",
                            }}
                            color="dark"
                            fontSize="medium"
                          />
                        </SoftBox>
                        <SoftBox display="flex" flexDirection="column">
                          <SoftTypography
                            variant="button"
                            color="dark"
                            fontWeight="medium"
                            gutterBottom
                          >
                            Notes
                          </SoftTypography>
                          <SoftTypography variant="caption" color="text">
                            {bookingData.notes}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                )}
                {bookingData.extendedProps?.pilot?.value &&
                bookingData.extendedProps?.instructor?.value &&
                !bookingData?.extendedProps?.lesson ? (
                  <Grid item xs={12} mt={3}>
                    <TaskTable tasks={tasks} taskListId={taskListId} />
                  </Grid>
                ) : null}
                {bookingData.flightData && bookingData.completed && (
                  <Grid item xs={12} mt={3}>
                    <SoftBox
                      sx={{ backgroundColor: "#E9ecef", borderRadius: "5px" }}
                      p={3}
                      justifyContent="center"
                      textAlign="center"
                    >
                      <SoftTypography variant="body2" fontWeight="medium">
                        Completed By:{" "}
                        {
                          clubUsers.get(
                            bookingData.completedBy?.id ||
                              bookingData.completedBy
                          )?.displayName
                        }
                      </SoftTypography>
                      <Divider />
                      <SoftBox justifyContent="center" textAlign="center">
                        <Grid container spacing={1}>
                          <Grid item xs={4} pt={0}>
                            {bookingData.flightData?.startHobbs >= 0 ? (
                              <>
                                <SoftTypography variant="body2" color="text">
                                  <b>Hobbs Out</b>
                                </SoftTypography>
                                <SoftTypography variant="body2" color="text">
                                  {bookingData.flightData?.startHobbs >= 0
                                    ? bookingData.flightData?.startHobbs
                                    : "N/A"}
                                </SoftTypography>
                              </>
                            ) : null}
                            {bookingData.flightData?.startTach1 >= 0 ? (
                              <>
                                <SoftTypography variant="body2" color="text">
                                  <b>Tach Out</b>
                                </SoftTypography>
                                <SoftTypography variant="body2" color="text">
                                  {bookingData.flightData?.startTach1 >= 0
                                    ? bookingData.flightData?.startTach1
                                    : "N/A"}
                                </SoftTypography>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={4}>
                            {bookingData.flightData?.endHobbs >= 0 ? (
                              <>
                                <SoftTypography variant="body2" color="text">
                                  <b>Hobbs In</b>
                                </SoftTypography>
                                <SoftTypography variant="body2" color="text">
                                  {bookingData.flightData?.endHobbs || "N/A"}
                                </SoftTypography>
                              </>
                            ) : null}
                            {bookingData.flightData?.endTach1 >= 0 ? (
                              <>
                                <SoftTypography variant="body2" color="text">
                                  <b>Tach In</b>
                                </SoftTypography>
                                <SoftTypography variant="body2" color="text">
                                  {bookingData.flightData?.endTach1 || "N/A"}
                                </SoftTypography>
                              </>
                            ) : null}
                          </Grid>
                          <Grid item xs={4}>
                            {(bookingData.flightData?.totalHobbsHours ||
                              bookingData.flightData?.totalTach1Hours) && (
                              <SoftTypography variant="body2" color="text">
                                <b>Flight Hours:</b>{" "}
                                {bookingData.flightData?.totalHobbsHours ||
                                bookingData.flightData?.totalTach1Hours
                                  ? Math.round(
                                      (bookingData.flightData
                                        ?.totalHobbsHours ||
                                        bookingData.flightData
                                          ?.totalTach1Hours) * 10
                                    ) / 10
                                  : "N/A"}
                              </SoftTypography>
                            )}
                            <SoftTypography variant="body2" color="text">
                              <b>Instruction Hours:</b>{" "}
                              {bookingData.flightData?.instructionHoursGround ||
                              bookingData.flightData?.instructionHours
                                ? Math.round(
                                    ((bookingData.flightData
                                      ?.instructionHours || 0) +
                                      (bookingData.flightData
                                        ?.instructionHoursGround || 0)) *
                                      10
                                  ) / 10
                                : "N/A"}
                            </SoftTypography>
                          </Grid>
                        </Grid>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                )}
                <Grid item xs={12} mt={3}>
                  <Notes
                    fbRef={doc(
                      getClubBookingsCollection(
                        selectedClubId,
                        selectedLocationId
                      ),
                      bookingId
                    )}
                  />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        )}
        {(!bookingData.completed ||
          bookingData.extendedProps.type.value === "maintenance") &&
        canViewBookingDetails(bookingData) ? (
          <Grid item xs={12} sm={3}>
            <SoftBox
              mt={1}
              shadow="sm"
              sx={{ height: "100%" }}
              borderRadius="lg"
              p={2}
            >
              {bookingData.confirmed && (
                <>
                  {bookingData.dispatched ? (
                    <>
                      {selectedLocation?.preferences?.requireLessonGrading &&
                      bookingData?.extendedProps?.lesson &&
                      !bookingData?.extendedProps?.lesson?.graded ? (
                        <WithPermissions
                          permissions={
                            systemPermissions.CHECK_IN_BOOKINGS_WITHOUT_GRADES
                          }
                        >
                          <WithPermissions
                            permissions={
                              bookingData?.extendedProps?.pilot?.value ===
                                loggedUserId ||
                              bookingData?.extendedProps?.pilot2?.value ===
                                loggedUserId ||
                              bookingData?.extendedProps?.instructor?.value ===
                                loggedUserId
                                ? systemPermissions.CHECK_IN_OWN_BOOKING
                                : systemPermissions.CHECK_IN_OTHERS_BOOKING
                            }
                          >
                            <SoftButton
                              variant="contained"
                              color="error"
                              fullWidth
                              onClick={() => handleCheckInOpen()}
                              sx={{ mt: 1 }}
                            >
                              Check In
                            </SoftButton>
                          </WithPermissions>
                        </WithPermissions>
                      ) : (
                        <WithPermissions
                          permissions={
                            bookingData?.extendedProps?.pilot?.value ===
                              loggedUserId ||
                            bookingData?.extendedProps?.pilot2?.value ===
                              loggedUserId ||
                            bookingData?.extendedProps?.instructor?.value ===
                              loggedUserId
                              ? systemPermissions.CHECK_IN_OWN_BOOKING
                              : systemPermissions.CHECK_IN_OTHERS_BOOKING
                          }
                        >
                          <SoftButton
                            variant="contained"
                            color="error"
                            fullWidth
                            onClick={() => handleCheckInOpen()}
                            sx={{ mt: 1 }}
                          >
                            Check In
                          </SoftButton>
                        </WithPermissions>
                      )}

                      {(bookingData?.extendedProps?.lesson || taskListId) &&
                        loggedUserId ===
                          bookingData?.extendedProps?.instructor?.value && (
                          <SoftButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleGradeInstructionOpen()}
                            sx={{ mt: 1 }}
                          >
                            Grade {taskListId ? "Tasks" : "Lesson"}
                          </SoftButton>
                        )}
                    </>
                  ) : (
                    !shouldShowAcceptTransfer && (
                      <WithPermissions
                        permissions={
                          bookingData?.extendedProps?.pilot?.value ===
                            loggedUserId ||
                          bookingData?.extendedProps?.pilot2?.value ===
                            loggedUserId ||
                          bookingData?.extendedProps?.instructor?.value ===
                            loggedUserId
                            ? systemPermissions.CHECK_OUT_OWN_BOOKING
                            : systemPermissions.CHECK_OUT_OTHERS_BOOKING
                        }
                      >
                        <SoftButton
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => handleCheckOutOpen()}
                        >
                          Check Out
                        </SoftButton>
                      </WithPermissions>
                    )
                  )}

                  {!shouldShowAcceptTransfer && (
                    <SoftButton
                      variant="contained"
                      color="success"
                      fullWidth
                      sx={{ mt: 1 }}
                      onClick={handlePrintDispatch}
                    >
                      Print Dispatch
                    </SoftButton>
                  )}
                  {shouldShowTransferButton && (
                    <SoftButton
                      variant="contained"
                      color="warning"
                      fullWidth
                      sx={{ mt: 1 }}
                      onClick={() =>
                        openModal$.next({
                          modalName: "TRANSFER_BOOKING_MODAL",
                          modalProps: { bookingData },
                        })
                      }
                    >
                      Transfer
                    </SoftButton>
                  )}
                  {!shouldShowAcceptTransfer && <Divider />}
                </>
              )}
              {(shouldShowAcceptTransfer ||
                (!bookingData.confirmed &&
                  (loggedUserId ===
                    bookingData.extendedProps?.instructor?.value ||
                    hasAccess([
                      systemPermissions.ACCEPT_OTHERS_PENDING_BOOKINGS,
                    ])))) && (
                <>
                  <SoftButton
                    variant="contained"
                    color="success"
                    fullWidth
                    onClick={() =>
                      shouldShowAcceptTransfer
                        ? handleBookingTransfer()
                        : handleAcceptBooking()
                    }
                    sx={{ mt: 1 }}
                  >
                    Accept
                  </SoftButton>
                  <SoftButton
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() =>
                      shouldShowAcceptTransfer
                        ? handleCancelBookingTransfer()
                        : handleRejectBooking()
                    }
                    sx={{ mt: 1 }}
                  >
                    Reject
                  </SoftButton>
                  <Divider />
                </>
              )}
              {canEditBooking(bookingData) && (
                <SoftButton
                  variant="outlined"
                  color="dark"
                  fullWidth
                  onClick={handleEditModalOpen}
                >
                  Edit
                </SoftButton>
              )}
              {bookingData.dispatched ? (
                <SoftButton
                  variant="outlined"
                  color="dark"
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={() => handleUndoCheckOut()}
                >
                  Undo Check Out
                </SoftButton>
              ) : null}
              {(bookingData.confirmed ||
                loggedUserId !==
                  bookingData.extendedProps?.instructor?.value) && (
                <SoftButton
                  variant="outlined"
                  color="error"
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={() => handleCancelOpen()}
                >
                  Cancel
                </SoftButton>
              )}
            </SoftBox>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={3}>
              {canEditBooking(bookingData) ? (
                <SoftBox
                  mt={1}
                  shadow="sm"
                  sx={{ height: "100%" }}
                  borderRadius="lg"
                  p={2}
                >
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleEditModalOpen}
                  >
                    Edit
                  </SoftButton>
                  <SoftButton
                    variant="outlined"
                    color="error"
                    fullWidth
                    sx={{ mt: 1 }}
                    onClick={() => handleCancelOpen()}
                  >
                    Cancel
                  </SoftButton>
                  {bookingData.transactionId &&
                    (hasAccess(systemPermissions.VIEW_INVOICES) ||
                      bookingData?.extendedProps?.pilot?.value ===
                        loggedUserId ||
                      bookingData?.extendedProps?.pilot2?.value ===
                        loggedUserId ||
                      bookingData?.extendedProps?.instructor?.value ===
                        loggedUserId) && (
                      <SoftButton
                        variant="outlined"
                        color="info"
                        fullWidth
                        sx={{ mt: 1 }}
                        onClick={() => handleViewInvoice()}
                      >
                        View Invoice
                      </SoftButton>
                    )}
                </SoftBox>
              ) : (
                bookingData.transactionId &&
                (hasAccess(systemPermissions.VIEW_INVOICES) ||
                  bookingData?.extendedProps?.pilot?.value === loggedUserId ||
                  bookingData?.extendedProps?.pilot2?.value === loggedUserId ||
                  bookingData?.extendedProps?.instructor?.value ===
                    loggedUserId) && (
                  <SoftBox
                    mt={1}
                    shadow="sm"
                    sx={{ height: "100%" }}
                    borderRadius="lg"
                    p={2}
                  >
                    <SoftButton
                      variant="outlined"
                      color="info"
                      fullWidth
                      sx={{ mt: 1 }}
                      onClick={() => handleViewInvoice()}
                    >
                      View Invoice
                    </SoftButton>
                  </SoftBox>
                )
              )}
            </Grid>
          </>
        )}
      </Grid>
      {auditTrail?.length > 0 && (
        <CustomizedAccordion sx={{ marginTop: "16px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="bookingPanel-content"
          >
            <SoftTypography>Reservation History</SoftTypography>
          </AccordionSummary>
          <AccordionDetails>
            <SoftBox mt={1} shadow="xs" borderRadius="md" p={2}>
              {auditTrail
                ?.sort((a, b) => b.date - a.date)
                .map((log) => (
                  <AuditTrailCard
                    action={log.action}
                    date={log.date}
                    user={log.user}
                    title={log.title}
                    diff={log.diff}
                    key={log.id}
                  />
                ))}
            </SoftBox>
          </AccordionDetails>
        </CustomizedAccordion>
      )}
      <Modal
        open={openUserInfo}
        onClose={handleUserInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <UserInfoModal
            handleClose={handleUserInfoClose}
            userId={selectedUserId}
          />
        </SoftBox>
      </Modal>
    </ModalContainer>
  );
}

DynamicPropCard.propTypes = {
  dynamicProp: PropTypes.object.isRequired,
  bookingData: PropTypes.object.isRequired,
};

BookingInfoModal.propTypes = {
  bookingId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  resources: PropTypes.array.isRequired,
  pilots: PropTypes.array.isRequired,
};

export default BookingInfoModal;
